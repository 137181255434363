import { FaHome, FaUserFriends } from 'react-icons/fa';
import { IonActionSheet, IonFooter, IonToolbar } from '@ionic/react';
import {
	addOutline,
	caretForwardCircle,
	chatboxOutline,
	enterOutline,
	trash,
} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import './Footer.css';
import { AiFillSetting } from 'react-icons/ai';
import { BiCalendarEvent } from 'react-icons/bi';
import { BiHomeAlt } from 'react-icons/bi';
import { IoIosAddCircle } from 'react-icons/io';
import { VscAdd } from 'react-icons/vsc';

const Footer = ({}) => {
	const [userData] = useState(
		JSON.parse(localStorage.getItem('userData'))?.data
	);
	const [showActionSheet, setShowActionSheet] = useState(false);

	const router = useHistory();
	const location = useLocation();

	return (
		<IonFooter className="relative">
			<div className="absolute fr-center-around-full">
				<div
					className={`w-1/5 ${
						location.pathname === '/home'
							? 'bg-white'
							: 'bg-transparent'
					} h-1 z-50`}></div>
				<div
					className={`w-1/5 ${
						location.pathname === '/events'
							? 'bg-white'
							: 'bg-transparent'
					} h-1 z-50`}></div>
				<div
					className={`w-1/5 ${
						false ? 'bg-white' : 'bg-transparent'
					} h-1 z-50`}></div>
				<div
					className={`w-1/5 ${
						location.pathname === '/friends'
							? 'bg-white'
							: 'bg-transparent'
					} h-1 z-50`}></div>
				<div
					className={`w-1/5 ${
						location.pathname === '/settings'
							? 'bg-white'
							: 'bg-transparent'
					} h-1 z-50`}></div>
			</div>
			<IonToolbar color="tertiary">
				<div className="fr-center-around-full py-3 bg-otto-tertiary dark:bg-otto-tertiary-dark rounded-lg mb-1">
					<BiHomeAlt
						onClick={() => {
							router.push('/home');
						}}
						className={`footer-icon ${
							location.pathname === '/home'
								? 'text-white'
								: 'text-black'
						}`}
					/>
					{/* <BiCalendarEvent
						onClick={() => {
							router.push('/events');
						}}
						className={`footer-icon ${
							location.pathname === '/events'
								? 'text-white'
								: 'text-black'
						}`}
					/> */}
					<IoIosAddCircle
						className="text-3xl text-black"
						onClick={() => setShowActionSheet(true)}
						expand="block"
					/>
					<IonActionSheet
						isOpen={showActionSheet}
						cssClass="my-custom-class"
						onDidDismiss={() => setShowActionSheet(false)}
						buttons={[
							{
								text: 'Create a Party',
								icon: addOutline,
								handler: () => {
									router.push('/createparty');
								},
							},
						]}></IonActionSheet>
					<AiFillSetting
						onClick={() => {
							router.push('/settings');
						}}
						className={`footer-icon ${
							location.pathname === '/settings'
								? 'text-white'
								: 'text-black'
						}`}
					/>
				</div>
			</IonToolbar>
		</IonFooter>
	);
};

export default Footer;
