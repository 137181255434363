import { useEffect, useRef, useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';

const PhonePinInput = ({ SetState }) => {
	const idInput1 = useRef(null);
	const idInput2 = useRef(null);
	const idInput3 = useRef(null);
	const idInput4 = useRef(null);

	const [i1, si1] = useState(null);
	const [i2, si2] = useState(null);
	const [i3, si3] = useState(null);
	const [i4, si4] = useState(null);

	useEffect(() => {
		let pin = [i1, i2, i3, i4].filter(Boolean).join();
		SetState(pin.toString().replaceAll(',', ''));
	}, [i1, i2, i3, i4]);

	const ChangeIdBox = (index, event) => {
		console.log('here now');
		switch (index) {
			case 1:
				if (event.keyCode === 8) {
					event.preventDefault();
					idInput1.current.value = null;
				} else if (isFinite(String.fromCharCode(event.keyCode))) {
					event.preventDefault();
					idInput1.current.value = String.fromCharCode(event.keyCode);
					si1(idInput1.current.value);
					idInput2.current.focus();
				}
				break;

			case 2:
				if (event.keyCode === 8) {
					event.preventDefault();
					idInput2.current.value = null;
					idInput1.current.focus();
				} else if (isFinite(String.fromCharCode(event.keyCode))) {
					event.preventDefault();
					idInput2.current.value = String.fromCharCode(event.keyCode);
					si2(idInput2.current.value);
					idInput3.current.focus();
				}
				break;

			case 3:
				if (event.keyCode === 8) {
					event.preventDefault();
					idInput3.current.value = null;
					idInput2.current.focus();
				} else if (isFinite(String.fromCharCode(event.keyCode))) {
					event.preventDefault();
					idInput3.current.value = String.fromCharCode(event.keyCode);
					si3(idInput3.current.value);
					idInput4.current.focus();
				}
				break;

			case 4:
				if (event.keyCode === 8) {
					event.preventDefault();
					idInput4.current.value = null;
					idInput3.current.focus();
				} else if (isFinite(String.fromCharCode(event.keyCode))) {
					event.preventDefault();
					idInput4.current.value = String.fromCharCode(event.keyCode);
					si4(idInput4.current.value);
					document.activeElement.blur();
				}
				break;

			default:
				event.preventDefault();
				break;
		}
	};

	return (
		<div className="flex flex-row text-center text-black">
			<input
				ref={idInput1}
				onKeyDown={(event) => {
					ChangeIdBox(1, event);
				}}
				type="number"
				className="phone-pin-box"
			/>
			<input
				ref={idInput2}
				onKeyDown={(event) => {
					ChangeIdBox(2, event);
				}}
				type="number"
				className="phone-pin-box"
			/>
			<input
				ref={idInput3}
				onKeyDown={(event) => {
					ChangeIdBox(3, event);
				}}
				type="number"
				className="phone-pin-box"
			/>
			<input
				ref={idInput4}
				onKeyDown={(event) => {
					ChangeIdBox(4, event);
				}}
				type="number"
				className="phone-pin-box"
			/>
		</div>
	);
};

export default PhonePinInput;
