import { useRef, useState } from 'react';

import { IonInput } from '@ionic/react';

const MdPasswordInput = ({ AdditionalTW, Placeholder, SetState, isMd }) => {
	const [isInputFocused, setInputFocusStatus] = useState(false);

	const passwordInput = useRef();

	const HandleChange = () => {
		SetState(passwordInput.current.value);
	};

	return (
		<IonInput
			ref={passwordInput}
			type="password"
			placeholder={Placeholder || 'Password'}
			className={`${AdditionalTW} + 
				${
					isInputFocused
						? isMd
							? 'input-text-focused-md'
							: 'input-text-focused-full'
						: isMd
						? 'input-text-md'
						: 'input-text-full'
				}
			`}
			onIonFocus={() => {
				setInputFocusStatus(true);
			}}
			onIonBlur={() => {
				setInputFocusStatus(false);
			}}
			onIonChange={HandleChange}
		/>
	);
};

export default MdPasswordInput;
