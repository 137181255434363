import { AiOutlineClose } from 'react-icons/ai';
import MdLoadingButton from '../UI/MdLoadingButton';
import OutsideClickHandler from 'react-outside-click-handler';
import PhonePinInput from '../UI/Phone/PhonePinInput';
import ReactCodeInput from 'react-verification-code-input';
import { useRef } from 'react';
const SMSAuthModal = ({
	SetState,
	SMSClickFunction,
	RenewSMS,
	SetPin,
	IsModalLoading,
}) => {
	
	
	const updateCode = (vals) => {
		SetPin(vals);
		console.log(vals);
	};
	return (
		<OutsideClickHandler
			onOutsideClick={() => {
				SetState(false);
			}}>
			<div className="modal">
				<div className="w-full h-full relative fc-center-around-full">
					<AiOutlineClose
						onClick={() => {
							SetState(false);
						}}
						className="absolute top-2 right-2 text-2xl"
					/>
					<div className="text-center text-xl font-semibold">
						SMS Verification
					</div>
					<div className="fc-center-full">
						<div className="w-2/3 text-sm text-center mb-3">
							Please enter code that was texted to you
						</div>
						
						<ReactCodeInput
								className="code-input"
								onChange={updateCode}
								fields={4}
							/>
						<div
							onClick={RenewSMS}
							className="w-2/3 text-sm text-center mt-3 hover:underline">
							Didn't receive text? Click here to send again
						</div>
					</div>
					<MdLoadingButton
						Message="Submit"
						isLoading={IsModalLoading}
						ClickFunction={SMSClickFunction}
						AdditionalTW="border border-otto-tertiary dark:border-otto-tertiary-dark"
					/>
				</div>
			</div>
		</OutsideClickHandler>
	);
};

export default SMSAuthModal;
