import fetch from 'unfetch';

const changePassword = async (oldPassword, newPassword) => {
	const url = `/api/profile/changePassword.js`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`, //JWT verification. Pulling from token provided at login.
		},
		body: JSON.stringify({
			oldPassword: oldPassword,
			newPassword: newPassword,
		}), //Stringify and pass the body content
	});

	if (res.ok) {
		console.log('succ');
		return 0;
	} else {
		console.log('submitSignup ERROR');
		return 1;
	}
};

export default changePassword;
