import fetch from 'unfetch';

const generateAuthToken = async () => {
	const url = `/api/spotify/spotifyauthtoken`;
	const res = await fetch(url, {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
	});

	if (res.ok) {
		let h = await res.json();
		if (h?.access_token?.access_token !== undefined) {
			localStorage.setItem(
				'spotify_access_token',
				h?.access_token?.access_token
			);
		}
		return true;
	} else {
		return false;
	}
};
export default generateAuthToken;
