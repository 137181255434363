import fetch from 'unfetch';

const removeSongFromQueue = async (party_id, track_id) => {
	const url = `/api/spotify/deletefromqueue`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			partyId: party_id,
			track: track_id,
		}),
	});
	console.log("heeeere")
	if (res.ok) {
		let h = await res.json();
		console.log(h);
		// if (h?.access_token?.access_token !== undefined) {
		// 	localStorage.setItem(
		// 		'spotify_access_token',
		// 		h?.access_token?.access_token
		// 	);
		// }
		return true;
	} else {
		let h = await res.json();
		console.log(h);
		return false;
	}
};
export default removeSongFromQueue;
