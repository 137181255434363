import { IonBackButton, IonButtons, IonHeader, IonToolbar } from '@ionic/react';

import React from 'react';

const BackgroundHeader = () => {
	return (
		<IonHeader>
			<IonToolbar color="medium"></IonToolbar>
		</IonHeader>
	);
};

export default BackgroundHeader;
