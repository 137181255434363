import { FaSpotify } from 'react-icons/fa';
const SpotifySongTag = ({ songData, SetState, SetSecondState }) => {
	return (
		<div
			onClick={() => {
				SetState(false);
				SetSecondState(songData, true);
			}}
			className="fr-full p-2">
			{songData?.album?.images[0] ? (
				<img
					src={songData.album.images[0].url}
					alt="song_img"
					className="h-12 w-12 object-cover"
				/>
			) : null}
			<div className="fc-full justify-center ml-1">
				<div className="text-black font-bold w-5/6 whitespace-wrap truncate">
					{songData?.name}
				</div>
				<div className="text-gray-600">
					{songData?.artists[0]?.name}
				</div>
			</div>
		</div>
	);
};

export default SpotifySongTag;
