import { BiDownArrow, BiRightArrow, BiUpArrow } from 'react-icons/bi';
import { FcDislike, FcLike } from 'react-icons/fc';
import { IonContent, IonPage, IonToast } from '@ionic/react';
import { checkmark, checkmarkCircle } from 'ionicons/icons';
import { useEffect, useState } from 'react';

import BackArrow from '../../components/General/Navigation/BackArrow';
import BackgroundHeader from '../../components/General/UI/BackgroundHeader';
import { BsCheckAll } from 'react-icons/bs';
import { CgSpinnerTwo } from 'react-icons/cg';
import { GoLocation } from 'react-icons/go';
import MdLoadingButton from '../../components/General/UI/MdLoadingButton';
import MdTelInput from '../../components/General/UI/Forms/Md/MdTelInput';
import MdTextInput from '../../components/General/UI/Forms/Md/MdTextInput';
import RSVPInfo from '../../data/party/RSVPInfo';
import RSVPPopUp from '../../components/Party/RSVP';
import SMSAuthModal from '../../components/General/Modals/SMSAuthModal';
import { VerifyPhoneNumber } from '../../components/utils/FormInputVerification';
import joinPartySMS from '../../data/party/join/joinPartySMS';
import { useHistory } from 'react-router';
import verifyPartySMS from '../../data/party/join/verifyPartySMS';
import { GiPartyPopper } from 'react-icons/gi';
import { GiBalloons } from 'react-icons/gi';

const RSVPPartyPage = ({ match }) => {
	const [isLoading, setLoadingStatus] = useState(false);
	const [pid, setPid] = useState(null);
	const [partyInfo, setPartyInfo] = useState(null);
	const [date, setDate] = useState(null);
	const [rsvp, setRSVP] = useState(null);
	const [showRSVP, setShowRSVP] = useState(false);
	const [showRSVPPopUp, setShowRSVPPopUp] = useState(false);
	const [time, setTime] = useState(null);
	const [didRSVP, setDidRSVP] = useState(false);
	useEffect(() => {
		setPid(match?.params?.pid);
		console.log(pid);
		if (localStorage.getItem('RSVP')) {
			console.log('RSVP DATA');
			let p = JSON.parse(localStorage.getItem('RSVP'));
			let h = match?.params?.pid;
			h = h.toString();
			console.log(p[h]);
			if (p[pid]) {
				console.log('WOAH');
				setDidRSVP(true);
			}
		}
	}, []);
	useEffect(() => {
		if (localStorage.getItem('RSVP')) {
			let d = JSON.parse(localStorage.getItem('RSVP'));
			console.log(d);
			let isMatch = false;
			if (d[pid]) {
				console.log('Is match!');
			} else {
				d[pid] = true;
				localStorage.setItem('RSVP', JSON.stringify(d));
			}
		} else if (didRSVP) {
			let n = {};
			console.log(match?.params?.pid);
			n[match?.params?.pid] = true;
			console.log(n);
			localStorage.setItem('RSVP', JSON.stringify(n));
		}
	}, [didRSVP]);
	useEffect(() => {
		setPid(match?.params?.pid);
		console.log(pid);
	}, [match]);
	useEffect(() => {
		if (pid) {
			RSVPInfo(pid).then((data) => {
				console.log(data?.data?.rsvp);
				if (data?.status !== 'error') {
					setPartyInfo(data?.data?.data);
					let newArr = data?.data?.rsvp;
					console.log('NEW ARR Is ', newArr);
					setRSVP(newArr);
					let d = new Date(data?.data?.data?.start_time);

					setDate(d);
					var result = date?.toLocaleTimeString(navigator.language, {
						hour: '2-digit',
						minute: '2-digit',
					});
					setTime(result);
					console.log(time);
				}
			});
		}
	}, [pid]);
	useEffect(() => {
		if (pid) {
			RSVPInfo(pid).then((data) => {
				console.log(data?.data?.rsvp);
				if (data?.status !== 'error') {
					setPartyInfo(data?.data?.data);
					let newArr = data?.data?.rsvp;
					console.log('NEW ARR Is ', newArr);
					setRSVP(newArr);
					let d = new Date(data?.data?.data?.start_time);
					setDate(d);
					var result = date?.toLocaleTimeString(navigator.language, {
						hour: '2-digit',
						minute: '2-digit',
					});
					setTime(result);
					console.log(result);
				}
			});
		}
	}, [showRSVPPopUp]);
	return pid ? (
		<IonPage>
			<IonContent scrollY={true} color="primary" fullscreen>
				{partyInfo ? (
					<div className="h-screen w-screen fc-center-full justify-between bg-gray-600 text-white relative">
						<div className="fc-center-full">
							<div className="h-full w-full relative">
								<div className="absolute -top-4 w-full bg-gray-900 h-4 rounded-t-3xl"></div>
								<div className="mt-4 text-xs ml-8">
									You've been invited!
								</div>
								<div className="ml-8 mb-2 font-semibold text-4xl">
									<span className="opacity-100 z-50 text-white relative">
										{partyInfo?.party_name || 'Party Name'}
									</span>
									<span className="absolute left-7 top-10 text-black opacity-60 z-10">
										{partyInfo?.party_name || 'Party Name'}
									</span>
								</div>
								{showRSVPPopUp ? (
									<div className="absolute w-full h-screen top-0">
										<RSVPPopUp
											setDidRSVP={setDidRSVP}
											setState={setShowRSVPPopUp}
											partyId={
												partyInfo.partyId
											}></RSVPPopUp>
									</div>
								) : null}
								<img
									src={partyInfo?.party_image}
									className="w-5/6 h-2/5 rounded-lg mx-auto object-cover shadow-2xl border border-gray-900 border-4 h-80"
								/>
								{!showRSVP ? (
									<div className="fc-center-full">
										<div className=" mx-auto px-2 w-3/4 mt-4 bg-gray-500 rounded-lg shadow-xl text-center font-light fr-center">
											<GoLocation className="bg-gray-400 rounded-full p-1 text-2xl" />
											<div className="text-sm py-2 ml-2 shadow-2xl text-gray-100 font-semibold">
												{partyInfo?.location?.address
													.split(',', 3)
													.join(',')}
											</div>
										</div>
										<div className="mx-auto w-5/6 mt-4 grid grid-cols-2">
											<div className="bg-gray-400 rounded-lg font-light p-2 w-4/5 bg-opacity-70 mx-auto text-center shadow-2xl">
												<div className="text-left mb-2">
													Start Time
												</div>
												<div className="text-lg font-medium">
													{date?.toLocaleTimeString(
														navigator.language,
														{
															hour: '2-digit',
															minute: '2-digit',
														}
													)}
												</div>
											</div>
											<div className="bg-gray-400 rounded-lg font-light p-2 w-4/5 bg-opacity-70 mx-auto shadow-2xl">
												<div className=" mb-2">
													Date
												</div>
												<div className="text-lg font-medium text-center">
													{date
														?.toDateString()
														.substring(
															date
																?.toDateString()
																.indexOf(' ') +
																1,
															date
																?.toDateString()
																.lastIndexOf(
																	' '
																)
														)}
												</div>
											</div>
										</div>
										<div className="w-5/6 mt-8 mx-auto">
											<div className="text-lg">
												Description
											</div>
											<div className="font-light text-xs px-2 py-2">
												{partyInfo?.description}
											</div>
										</div>
									</div>
								) : (
									<div className="fc-center-center-full h-11/12 mt-3 overflow-y-scroll">
										<div className="h-72 overflow-y-scroll w-full">
											{rsvp?.map((id, ix) => {
												console.log(id);
												let colorArr = [
													'bg-green-400',
													'bg-red-400',
													'bg-blue-400',
													'bg-yellow-300',
													'bg-indigo-700',
													'bg-pink-300',
												];
												console.log(id);
												let colorArr2 = [
													'text-white',
													'text-white',
													'text-white',
													'text-black',
													'bg-indigo-700',
													'bg-pink-300',
												];
												let num = Math.round(
													Math.random() * 4
												);

												console.log(colorArr[num]);
												return (
													<div
														className={`pl-8 text-left mt-4 py-1 font-light shadow-2xl font-medium flex ${colorArr[num]} shadow-inner shadow-2xl rounded-lg w-2/3 mx-auto text-center`}>
														<GiPartyPopper className="text-yellow-400 my-auto " />
														<GiBalloons className="text-red-400 my-auto mr-4" />
														<span
															className={`${colorArr2[num]}`}>
															{id?.first_name}{' '}
															{id?.last_name[0]}
															{'.'}
														</span>
													</div>
												);
											})}
										</div>
									</div>
								)}
								<div className="w-full mt-12">
									{rsvp ? (
										<div className="fc-center-full">
											<div
												className="fr-center-full ml-8"
												onClick={() => {
													setShowRSVP(!showRSVP);
												}}>
												<div>See who's attending</div>

												{showRSVP ? (
													<BiUpArrow className="mt-.5 ml-1" />
												) : (
													<BiRightArrow className="mt-.5 ml-1" />
												)}
											</div>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				) : (
					// <>
					// 	<div className="text-white w-full h-screen flex flex-col bg-gray-900 pb-24">
					// 		<div className="mx-auto text-6xl font-semibold mt-4 flex flex-col break-words">
					// 			<div className="mx-auto w-2/3 text-center mt-2 text-6xl">
					// 				{partyInfo.party_name}
					// 			</div>

					// 			<img
					// 				src={partyInfo?.party_image}
					// 				className="rounded-lg w-5/6 h-72 mx-auto mt-6"></img>

					// 			<div className=" text-sm font-light ml-8 text-left font-bold text-2xl">
					// 				{date?.toDateString()}
					// 			</div>
					// <div className="text-left text-sm ml-8 font-light mt-2">
					// 	{date?.toLocaleTimeString(
					// 		navigator.language,
					// 		{
					// 			hour: '2-digit',
					// 			minute: '2-digit',
					// 		}
					// 	)}
					// </div>
					// 		</div>
					// 		{showRSVPPopUp ? (
					// 			<div className="absolute w-full h-screen top-0">
					// 				<RSVPPopUp
					// 					setDidRSVP={setDidRSVP}
					// 					setState={setShowRSVPPopUp}
					// 					partyId={partyInfo.partyId}></RSVPPopUp>
					// 			</div>
					// 		) : null}
					// 		{!showRSVP ? (
					// 			<>
					// 				<div className="w-full flex bg-gray-900">
					// 					<div className="mx-auto p-2 w-3/4 mt-4 rounded-lg mx-auto text-center bg-gray-800 shadow-xl">
					// 						<div className="text-2xl">
					// 							{' '}
					// 							About the event:
					// 						</div>
					// <div className="font-light text-sm bg-gray-800 px-2 py-2">
					// 	{partyInfo?.description}
					// </div>
					// <div className="mx-auto px-2 w-5/6 mt-4 shadow-xl text-center text-xs font-light text-lg bg-gray-400 rounded-lg">
					// 	<div className="text-xs -mb-4 py-2">
					// 		Location:
					// 	</div>
					// 	<div className="text-sm py-2">
					// 		{
					// 			partyInfo?.location
					// 				?.address
					// 		}
					// 	</div>
					// </div>
					// 					</div>
					// 				</div>
					// 			</>
					// 		) : null}

					// <div className="w-full flex bg-gray-900 ">
					// 	{rsvp ? (
					// 		<div className="w-full flex flex-col mb-24">
					// 			<div
					// 				className="flex duration-1000 mb-4 font-bold ml-4 mt-4 z-40"
					// onClick={() => {
					// 	setShowRSVP(!showRSVP);
					// }}>
					// 				Who is Attending ?{' '}
					// 				{showRSVP ? (
					// 					<BiDownArrow className="rotate-180 my-auto ml-2" />
					// 				) : (
					// 					<BiRightArrow className="rotate-180 my-auto ml-2" />
					// 				)}
					// 			</div>
					// 			{showRSVP ? (
					// 				<div className="w-full overflow-scroll h-2/3">
					// {rsvp?.map((id, ix) => {
					// 	console.log(id);
					// 	return (
					// 		<div className="w-2/3 mx-auto text-left mt-2 font-light">
					// 			{id?.first_name}{' '}
					// 			{id?.last_name}
					// 		</div>
					// 	);
					// })}
					// 				</div>
					// 			) : null}
					// 		</div>
					// 	) : null}
					// </div>
					// 	</div>
					// </>

					<div className="w-full h-full flex bg-gray-900">
						<CgSpinnerTwo className="text-8xl animate-spin my-auto mx-auto text-green-300" />
					</div>
				)}
			</IonContent>
			{!didRSVP ? (
				<div className="w-full mx-auto absolute flex text-xl rounded-lg py-2 bottom-4 left-64 z-0">
					<button
						onClick={() => {
							console.log('SHOWING RSVP');
							setShowRSVPPopUp(true);
						}}
						className="py-2 px-4 bg-green-500 rounded-lg shadow-2xl animate-bounce duration-1000">
						RSVP!
					</button>
				</div>
			) : (
				<div className="w-full mx-auto absolute flex text-xl rounded-lg py-2 bottom-4 left-60">
					<div className="py-2 px-4 bg-green-500 rounded-lg shadow-2xl flex">
						<button onClick={() => {}} className="">
							RSVP'd!
						</button>
						<BsCheckAll />
					</div>
				</div>
			)}
		</IonPage>
	) : (
		<IonPage>
			<BackgroundHeader />
			<IonContent scrollY={false} color="primary" fullscreen></IonContent>
			<div className="w-full h-full flex z-50 bg-gray-900">
				<CgSpinnerTwo className="text-8xl animate-spin my-auto mx-auto text-white" />
			</div>
		</IonPage>
	);
};

export default RSVPPartyPage;
