import { IonImg, IonToast } from '@ionic/react';
import { useLocation } from 'react-router';
import { FcCancel } from 'react-icons/fc';
import addSongToQueue from '../../../../data/Spotify/addSongToQueue';
import { useEffect, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import removeSongFromQueue from '../../../../data/Spotify/removeSongFromQueue';
import Spinner from '../Loading/spinner';
const SpotifyQueueTag = ({ songData, SetState, SetSecondState }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [didLoadSucc, setDidLoadSucc] = useState(0);
	const [queueMessage, setQueueMessage] = useState('Added to queue');
	const [didQueue, setDidQueue] = useState(false);
	const [visib, setVisib] = useState('');
	console.log(songData);
	const location = useLocation();
	const partyId = location.pathname.split('/')[2];
	useEffect(() => {
		if (didQueue) {
			setVisib('hidden');
		}
	}, [didQueue]);
	return (
		<div
			className={`fr-full w-full mt-2 border-opacity-20 border-2 border-blue-200 rounded-xl ${visib}`}>
			{songData?.song_image ? (
				<div className="overflow-hidden w-24 h-full rounded-lg flex-shrink-0">
					<IonImg
						src={songData?.song_image}
						alt="song_img"
						className="w-3/4 rounded-xl object-cover rounded-l-xl my-auto"
					/>
				</div>
			) : null}
			<div className="fc-full justify-center overflow-auto">
				<div className="w-full whitespace-nowrap truncate">
					{songData?.song_name}
				</div>
				<div className="subtext text-center">
					{songData?.song_artist}
				</div>
			</div>
			{!isLoading ? (
				<div className="flex flex-col px-2">
					<AiFillCheckCircle
						onClick={() => {
							setIsLoading(true);
							setQueueMessage('Added to queue');
							setDidLoadSucc(1);
							setTimeout(() => {
								setDidQueue(true);
							}, 800);
							addSongToQueue(partyId, songData?.trackId).then(
								(data) => {
									setIsLoading(false);
								}
							);
						}} 
						className="my-auto ml-2 text-2xl text-green-400"
					/>
					<span className="my-auto w-full bg-white h-1"></span>
					<FcCancel
						onClick={() => {
							setIsLoading(true);
							setQueueMessage('Removed from queue');
							setDidLoadSucc(1);
							setTimeout(() => {
								setDidQueue(true);
							}, 800);

							removeSongFromQueue(
								partyId,
								songData?.trackId
							).then((data) => {
								setIsLoading(false);
							});
						}}
						className="my-auto ml-2 text-2xl"
					/>
				</div>
			) : (
				<div className="flex">
					<Spinner className="w-1/3"></Spinner>
				</div>
			)}
			<IonToast
				position="middle"
				isOpen={didLoadSucc !== 0}
				message={queueMessage}
				color="secondary"
				duration={500}
				icon={AiFillCheckCircle}
				onDidDismiss={() => {
					setDidLoadSucc(0);
				}}></IonToast>
		</div>
	);
};

export default SpotifyQueueTag;
