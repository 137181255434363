import {
	CheckIfSameDate,
	GetDayIntFromDate,
	GetDayStringFromDate,
	GetMonthStringFromDate,
	GetTimeStringFromTimeStamp,
	GetYearFromData,
} from '../../../utils/FormatData';
import { useEffect, useState, useRef } from 'react';

import { BiCalendarEvent } from 'react-icons/bi';
import { BsChevronRight } from 'react-icons/bs';
import { IonImg } from '@ionic/react';
import { MdPinDrop } from 'react-icons/md';
import { FaShareSquare } from 'react-icons/fa';

const PartyTag = ({ SetStatus, partyData, Index }) => {
	const [isCurrentDay, setCurrentDayStatus] = useState(false);
	const [isUserHost, setUserHostStatus] = useState(false);
	const [shareParty, setShareParty] = useState(false);
	const [copied, setCopied] = useState(false);
	const textToCopy = useRef();
	useEffect(() => {
		if (CheckIfSameDate(partyData?.start_time)) {
			setCurrentDayStatus(true);
		}
	}, [partyData]);

	useEffect(() => {
		let user = JSON.parse(localStorage.getItem('userData'));
		console.log('UPN', user.data.phoneNumber);
		try {
			for (let i = 0; i < partyData?.hosts.length; i++) {
				if (partyData?.hosts[i] === user.data.phoneNumber) {
					setUserHostStatus(true);
				}
			}
		} catch {
			setUserHostStatus(false);
		}
	}, [partyData]);

	return (
		// <div
		// 	onClick={() => {
		// 		SetStatus(Index);
		// 	}}
		// 	className="fr-center-between-full rounded-xl pr-2 bg-gray-400">
		// 	<div className="fr-center">
		// 		{partyData?.party_image ? (
		// 			<img
		// 				src={partyData.party_image}
		// 				alt="party_photo"
		// 				className="h-20 w-24 object-cover rounded-l-xl"
		// 			/>
		// 		) : (
		// 			<div className="h-20 w-24 rounded-l-xl bg-otto-tertiary dark:bg-otto-tertiary-dark"></div>
		// 		)}
		// 		<div className="flex flex-col ml-1">
		// 			<div className="font-semibold mb-1">
		// 				{partyData?.party_name || 'No Party Name Found'}
		// 			</div>
		// 			{partyData?.start_time ? (
		// 				<div className="text-xs">
		// 					<BiCalendarEvent className="inline-block -mt-0.5 mr-1" />
		// 					{GetMonthStringFromDate(partyData?.start_time)}{' '}
		// 					{GetDayIntFromDate(partyData?.start_time)},{' '}
		// 					{GetYearFromData(partyData?.start_time)}
		// 				</div>
		// 			) : null}
		// 			<div className="text-xs">
		// 				<MdPinDrop className="inline-block" /> San Diego,
		// 				California (3.2 Miles)
		// 			</div>
		// 		</div>
		// 	</div>
		// 	<BsChevronRight className="text-xl" />
		// </div>
		<>
			<div
				onClick={() => {
					SetStatus(Index);
				}}
				className="fr-center-between-full rounded-xl bg-gray-200 dark:bg-gray-700 relative">
				<div className="h-full rounded-l-xl w-10 bg-otto-tertiary dark:bg-otto-tertiary-dark"></div>
				<div className="fr-center w-3/4">
					<div className="fc-center mx-3 w-10">
						<div className="text-sm -mb-0.5 text-otto-subtext dark:text-otto-subtext-dark">
							{GetDayStringFromDate(
								partyData?.start_time
							).toUpperCase()}
						</div>
						<div
							className={`font-bold text-2xl ${
								isCurrentDay
									? 'text-otto-tertiary dark:text-otto-tertiary-dark'
									: null
							}`}>
							{GetDayIntFromDate(partyData?.start_time)}
						</div>
						<div className="text-sm -mt-0.5 text-otto-subtext dark:text-otto-subtext-dark">
							{GetMonthStringFromDate(partyData?.start_time)}
						</div>
					</div>
					<div className="flex flex-col items-start h-full">
						{isUserHost ? (
							<div className="text-otto-secondary dark:text-otto-secondary-dark text-xs font-bold">
								HOST
							</div>
						) : null}
						<div className="w-3/4 truncate font-semibold -mt-0.5">
							{partyData?.party_name}
						</div>
						<div className="text-sm truncate text-otto-subtext dark:text-otto-subtext-dark">
							{GetTimeStringFromTimeStamp(partyData?.start_time)}{' '}
							- {GetTimeStringFromTimeStamp(partyData?.end_time)}
						</div>
						{partyData?.location ? (
							<div className="text-sm w-1/2 text-otto-subtext dark:text-otto-subtext-dark truncate">
								<span className="font-semibold">
									{
										partyData?.location?.address?.split(
											','
										)[0]
									}
								</span>
								,{' '}
								{partyData?.location?.address?.substring(
									partyData?.location?.address?.indexOf(',') +
										1
								)}
							</div>
						) : null}
					</div>
				</div>
				{partyData?.party_image ? (
					<div className="overflow-hidden w-24 h-24 rounded-lg flex-shrink-0">
						<IonImg
							src={partyData?.party_image}
							alt="profile_image"
							className="w-full h-full object-cover"
						/>
					</div>
				) : (
					<div className="bg-otto-tertiary dark:bg-otto-tertiary-dark w-24 h-24 rounded-lg flex-shrink-0"></div>
				)}
			</div>
			<div
				className="flex"
				onClick={() => {
					setShareParty(true);
				}}>
				Share <FaShareSquare className="my-auto text-2xl pl-2" />
			</div>

			{shareParty ? (
				<div className="w-full h-full absolute flex">
					<div className="w-2/3 mt-24 h-1/4 bg-gray-400 mx-auto rounded-lg flex flex-col z-50">
						<div
							onClick={() => {
								setShareParty(false);
								setCopied(false);
							}}
							className="text-right px-4 pt-2 text-2xl">
							X
						</div>
						<div className="text-center text-3xl mt-4 font-bold pb-2">
							Share this party!
						</div>
						<div className="flex flex-col mx-auto tex-center">
							<input
								className="mx-auto w-full px-2 bg-white rounded-xl text-green-400"
								ref={textToCopy}
								value={
									'https://beta.otto-party.com/joinparty/' +
									partyData?.partyId
								}></input>
							{!copied ? (
								<span
									className="mt-2 font-bold bg-green-400 px-2 py-2 animate-pulse text-center rounded-lg"
									onClick={() => {
										navigator.clipboard.writeText(
											textToCopy?.current?.value
										);
										setCopied(true);
									}}>
									Copy
								</span>
							) : (
								<span
									className="mt-2 font-bold bg-green-600 px-2 py-2 text-center rounded-lg"
									onClick={() => {
										navigator.clipboard.writeText(
											textToCopy?.current?.value
										);
									}}>
									Copied!
								</span>
							)}
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default PartyTag;
