import { IonContent, IonPage, IonToast } from '@ionic/react';

import BackArrow from '../../components/General/Navigation/BackArrow';
import BackgroundHeader from '../../components/General/UI/BackgroundHeader';
import MdLoadingButton from '../../components/General/UI/MdLoadingButton';
import MdPasswordInput from '../../components/General/UI/Forms/Md/MdPasswordInput';
import changePassword from '../../data/user/changePassword';
import { useState } from 'react';

const ChangePasswordPage = () => {
	const [oldPassword, setOldPassword] = useState(null);
	const [newPassword, setNewPassword] = useState(null);
	const [confirmNewPassword, setConfirmNewPassword] = useState(null);

	const [errorMessage, setErrorMessage] = useState(null);
	const [isPasswordChanging, setPasswordChangingStatus] = useState(false);

	const ChangePassword = () => {
		setPasswordChangingStatus(true);
		if (!oldPassword) {
			setErrorMessage('Please Enter Old Password');
			setPasswordChangingStatus(false);
			return null;
		}

		if (!newPassword) {
			setErrorMessage('Please Enter New Password');
			setPasswordChangingStatus(false);
			return null;
		}

		if (!confirmNewPassword) {
			setErrorMessage('Please Confirm New Password');
			setPasswordChangingStatus(false);
			return null;
		}

		if (newPassword !== confirmNewPassword) {
			setErrorMessage('Password Confirmation Must Match');
			setPasswordChangingStatus(false);
			return null;
		}

		changePassword(oldPassword, newPassword).then((resp) => {
			if (resp === 0) {
				setErrorMessage('Password changed succesfully');
				setPasswordChangingStatus(false);
			} else {
				setErrorMessage('Password change failed');
				setPasswordChangingStatus(false);
			}
		});
	};

	return (
		<IonPage>
			<BackgroundHeader />
			<IonContent scrollY={false} color="primary" fullscreen>
				<div className="fc-center-full-full relative justify-between">
					<BackArrow />
					<div className="text-xl font-heavy mt-3">
						Change Password
					</div>

					<div className="fc-center-md space-y-4">
						<MdPasswordInput
							SetState={setOldPassword}
							Placeholder="Old Password"
						/>
						<MdPasswordInput
							SetState={setNewPassword}
							Placeholder="New Password"
						/>
						<MdPasswordInput
							SetState={setConfirmNewPassword}
							Placeholder="Confirm New Password"
						/>
					</div>

					<MdLoadingButton
						isLoading={isPasswordChanging}
						Message="Change Password"
						AdditionalTW="mb-3"
						ClickFunction={ChangePassword}
					/>
				</div>
			</IonContent>
			<IonToast
				position="top"
				isOpen={errorMessage !== null}
				message={errorMessage}
				color="secondary"
				duration={2000}
				onDidDismiss={() => {
					setErrorMessage(null);
				}}></IonToast>
		</IonPage>
	);
};

export default ChangePasswordPage;
