import fetch from 'unfetch';
import useSWR from 'swr';

const GetSpotifyQueueSWR = async (partyId) => {
	const fetcher = (...args) =>
		fetch(...args, {
			headers: {
				authorization: localStorage.getItem('auth'),
				'content-type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify({ partyId: partyId }),
		}).then((res) =>
			res.json().then((data) => {
				return data;
			})
		);

	const { data: songs, error } = useSWR(`/api/spotify/getQueueReq`, fetcher, {
		refreshInterval: 3000,
	});

	console.log('SPOTIFY data is', songs);
	return songs;
};

export default GetSpotifyQueueSWR;
