import {
	GetDayIntFromDate,
	GetDayStringFromDate,
	GetMonthStringFromDate,
	GetTimeStringFromTimeStamp,
} from '../../components/utils/FormatData';
import {
	IonContent,
	IonPage,
	IonSlide,
	IonSlides,
	IonToast,
	IonToggle,
	IonLabel,
} from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { CgSpinnerTwo } from 'react-icons/cg';
import { AiFillExclamationCircle } from 'react-icons/ai';
import BackArrow from '../../components/General/Navigation/BackArrow';
import { FaRegEdit } from 'react-icons/fa';
import GPAboutSlide from '../../components/Party/GuestParty/GPAboutSlide';
import GPHomeSlide from '../../components/Party/GuestParty/GPHomeSlide';
import GPHostsSlide from '../../components/Party/GuestParty/GPHostsSlide';
import GetSpotifyQueueSWR from '../../data/swr/GetSpotifyQueueSWR';
import HPHostsSlide from '../../components/Party/HostParty/HPHostsSlide';
import HostModalBody from '../../components/General/Modals/HostModalBody';
import ModalOutline from '../../components/General/Modals/ModalOutline';
import ReportProblemModalBody from '../../components/General/Modals/ReportProblemModalBody';
import SearchUserModalBody from '../../components/General/Modals/SearchUserModalBody';
import SpotifyQueueTag from '../../components/General/UI/Tags/queueTag';
import SpotifySearchModalBody from '../../components/General/Modals/Spotify/SpotifySearchModalBody';
import SpotifySongTag from '../../components/General/UI/Tags/SpotifySongTag';
import UserModalBody from '../../components/General/Modals/UserModalBody';
import ConfirmationModal from '../../components/General/Modals/ConfirmationModal';
import fetchPartyInvites from '../../data/party/invites/fetchPartyInvites';
import fetchPartySettings from '../../data/party/settings';
import updatePartySettings from '../../data/party/settings';
import addSongToQueue from '../../data/Spotify/addSongToQueue';
import getPartyGuests from '../../data/party/getPartyGuests';
import GetPartyGuestsSWR from '../../data/swr/GetPartyGuestsSWR';
const HostPartyInfoPage = ({ match }) => {
	const [partyData, setPartyData] = useState(null);
	const [activeSlideIndex, setActiveSlideIndex] = useState(1);
	const [isUserModalActive, setUserModalStatus] = useState(false);
	const [isSearchUserModalActive, setSearchUserModalStatus] = useState(false);
	const [userModalData, setUserModalData] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isBackButtonActive, setBackButtonStatus] = useState(false);
	const [isAddHostModalActive, setAddHostModalStatus] = useState(false);
	const [isHostModalActive, setHostModalStatus] = useState(false);
	const [hostModalData, setHostModalData] = useState(null);
	const [songQueue, setSongQueue] = useState(null);
	const [isReportOverviewActive, setReportOverviewStatus] = useState(false);
	const [isOwner, setOwnerStatus] = useState(false);
	const [isConfirmationModalActive, setConfirmationModalStatus] =
		useState(false);
	const [confirmFunction1, setConfirmFunction1] = useState(null);
	const [confirmFunction2, setConfirmFunction2] = useState(null);
	const [confirmData, setConfirmData] = useState(null);
	const router = useHistory();
	const [spotifyQueueChecked, setSpotifyQueueChecked] = useState(false);
	const [guests, setGuests] = useState(null);
	const location = useLocation();
	const slides = useRef(null);
	const partyId = location.pathname.split('/')[2];
	let songQueueData = GetSpotifyQueueSWR(partyId);
	let guestInfoData = GetPartyGuestsSWR(partyId);
	useEffect(() => {
		songQueueData.then((resp) => {
			if (resp !== 'error') {
				setSongQueue(resp);
			}
		});
		// setSongQueue(songQueueData);
	}, [songQueueData]);
	useEffect(() => {
		guestInfoData.then((resp) => {
			if (resp) {
				setGuests(resp?.data?.data);
			}
		});
		// setSongQueue(songQueueData);
	}, [guestInfoData]);
	const [userData] = useState(
		JSON.parse(localStorage.getItem('userData'))?.data
	);
	useEffect(() => {
		getPartyGuests(partyId).then((data) => {
			setGuests(data?.data);
		});
		fetchPartyInvites(userData?.data?.phoneNumber).then((data) => {
			if (data?.data?.newArray) {
				for (let i in data?.data?.newArray) {
					if (
						data?.data?.newArray[i]?.partyId ===
						match.params.partyId
					) {
						setPartyData(data?.data?.newArray[i]);
						console.log('PARTY DATA', partyData);
						setSpotifyQueueChecked(partyData?.auto_queue);
					}
				}
			}
		});
	}, []);

	const slideOptions = {
		initialSlide: 1,
		speed: 400,
		pagination: true,
	};

	const SlideChanged = () => {
		slides.current.getActiveIndex().then((index) => {
			setActiveSlideIndex(index);
		});
	};

	const SetSlides = (index) => {
		slides.current.slideTo(index);
		setActiveSlideIndex(index);
	};

	const SetUserModalActive = (userData, isBack) => {
		setUserModalData(userData);
		setBackButtonStatus(isBack);
		setUserModalStatus(true);
	};

	const SetHostModalStatus = (hostData, isBack) => {
		let selectedHostDataArray = [];
		selectedHostDataArray.push(hostData);

		// setHostModalData(userData);

		// setBackButtonStatus(isBack);
		// setHostModalStatus(true);
	};

	const backButtonAction = () => {
		setHostModalStatus(false);
		setSearchUserModalStatus(true);
	};

	const RemoveHost = (Host) => {
		console.log('HOST TO BE REMOVED (NOT IMPLEMENTED)', Host);
		setErrorMessage('Host Removed');
	};

	const setConfirmationModalActive = (YesFunction, NoFunction, host) => {
		setConfirmFunction1(() => YesFunction);
		setConfirmFunction2(() => NoFunction);
		console.log(confirmFunction1);
		console.log('type:', typeof confirmFunction1);
		console.log(confirmFunction2);
		console.log('type:', typeof confirmFunction2);
		setConfirmData(host);
		setConfirmationModalStatus(true);
	};

	return (
		<IonPage>
			<IonContent scrollY={false} fullscreen color="primary">
				<BackArrow
					AdditionalTW="mix-blend-difference text-white z-50"
					RouteLocation="/home"
				/>
				{isSearchUserModalActive ? (
					<ModalOutline
						SetState={setSearchUserModalStatus}
						ModalType={SearchUserModalBody}
						SetSecondState={SetHostModalStatus}
					/>
				) : null}
				{isHostModalActive ? (
					<ModalOutline
						SetState={setHostModalStatus}
						ModalType={HostModalBody}
						ModalData={hostModalData}
						BackButtonStatus={isBackButtonActive}
						BackButtonAction={backButtonAction}
					/>
				) : null}
				{isUserModalActive ? (
					<ModalOutline
						SetState={setUserModalStatus}
						ModalType={UserModalBody}
						ModalData={userModalData}
						BackButtonStatus={isBackButtonActive}
					/>
				) : null}
				{isConfirmationModalActive ? (
					<ModalOutline
						SetState={setConfirmationModalStatus}
						ModalType={ConfirmationModal}
						BackButtonStatus={false}
						DynamicMessage={
							'Do you really want to remove this host?'
						}
						DynamicFunction1={confirmFunction1}
						DynamicFunction2={confirmFunction2}
						// SetSecondState={setErrorMessage}
						SetThirdState={false}
						ModalData={confirmData}
					/>
				) : null}
				<div className="fc-center-full-full">
					<div className="w-full">
						<div className="fr-center-center-full line-through text-4xl mt-2 border-b border-otto-tertiary dark:border-otto-tertiary-dark pb-2">
							OTTO
						</div>
						<div className="w-full flex flex-row items-center justify-between my-4 mb-3 px-2">
							<div className="flex flex-row items-center">
								<img
									src={partyData?.party_image}
									className="h-16 w-16 object-cover rounded-lg flex-shrink-0"></img>
								<div className="flex flex-col justify-center ml-2 -mt-0.5">
									<div className="text-lg font-semibold flex flex-row items-center">
										{partyData?.party_name}{' '}
										<span className="ml-2 -mt-1">
											<FaRegEdit />
										</span>
									</div>
									<div className="text-left text-otto-subtext dark:text-otto-subtext-dark -mt-1">{`${GetTimeStringFromTimeStamp(
										partyData?.start_time
									)} - ${GetTimeStringFromTimeStamp(
										partyData?.end_time
									)}`}</div>
									{partyData?.incidents &&
									partyData?.incidents.length > 0 ? (
										<div
											onClick={() => {
												setReportOverviewStatus(true);
											}}
											className="text-otto-danger w-full text-left text-base flex flex-row items-center">
											<span>
												<AiFillExclamationCircle className="mr-1" />
											</span>
											{partyData?.incidents.length}{' '}
											Problems Reported
										</div>
									) : (
										<div className="text-otto-tertiary dark:text-otto-secondary-dark w-full text-left text-xs">
											No Current Reported Problems
										</div>
									)}
								</div>
							</div>
							<div className="flex flex-col items-center ml-2 w-10">
								<div className="subtext -mb-0.5">
									{GetDayStringFromDate(
										partyData?.start_time
									).toUpperCase()}
								</div>
								<div className={`font-semibold text-2xl`}>
									{GetDayIntFromDate(partyData?.start_time)}
								</div>
								<div className="subtext -mt-0.5">
									{GetMonthStringFromDate(
										partyData?.start_time
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="w-full flex flex-row justify-around items-center mt-1 border-b border-otto-tertiary dark:border-otto-tertiary-dark">
						<div
							onClick={() => {
								SetSlides(0);
							}}
							className={`${
								activeSlideIndex === 0
									? 'bg-otto-tertiary dark:bg-otto-tertiary-dark rounded-t-xl font-medium'
									: null
							} w-1/3 text-center`}>
							Hosts
						</div>
						<div
							onClick={() => {
								SetSlides(1);
							}}
							className={`${
								activeSlideIndex === 1
									? 'bg-otto-tertiary dark:bg-otto-tertiary-dark rounded-t-xl font-medium'
									: null
							} w-1/3 text-center`}>
							Home
						</div>
						<div
							onClick={() => {
								SetSlides(2);
							}}
							className={`${
								activeSlideIndex === 2
									? 'bg-otto-tertiary dark:bg-otto-tertiary-dark rounded-t-xl font-medium'
									: null
							} w-1/3 text-center`}>
							About
						</div>
					</div>
					<IonSlides
						ref={slides}
						onIonSlideDidChange={SlideChanged}
						pager={true}
						options={slideOptions}
						className="w-full overflow-x-hidden h-2/3">
						<IonSlide>
							<HPHostsSlide
								HostData={partyData?.hosts}
								SetSearchUserModalStatus={
									setSearchUserModalStatus
								}
								SetUserModalActive={SetUserModalActive}
								isOwner={isOwner}
								RemoveHost={RemoveHost}
								SetConfirmActive={setConfirmationModalActive}
								ErrorMessage={setErrorMessage}
							/>
						</IonSlide>
						<IonSlide className="flex flex-col">
							<span>
								Auto Queue (allow users to queue songs directly)
							</span>
							<IonToggle
								color="light"
								checked={spotifyQueueChecked}
								onClick={() => {
									console.log(partyData);
									updatePartySettings(
										partyData?.partyId,
										'auto_queue',
										!spotifyQueueChecked
									);
									setSpotifyQueueChecked(
										!spotifyQueueChecked
									);
								}}
							/>

							{!spotifyQueueChecked ? (
								<div className="overflow-y-scroll w-4/5 h-3/4 border border-green-200 border-opacity-10 rounded-xl shadow-xl px-2 py-2 bg-opacity-30 bg-green-900">
									{songQueue?.map((index) => {
										return (
											<SpotifyQueueTag
												songData={
													index
												}></SpotifyQueueTag>
										);
									})}
								</div>
							) : null}
						</IonSlide>
						<IonSlide>
							<div>
								{!guests ? (
									<div>
										{guests?.map((id, idx) => {
											console.log('HERE');
											return (
												<div className="text-white">
													<div>POOPOO</div>
													{id}
												</div>
											);
										})}
									</div>
								) : (
									<div className="">
										<div>POOPOO</div>
										<CgSpinnerTwo className="animate-spin text-8xl" />
									</div>
								)}
							</div>
						</IonSlide>
					</IonSlides>
				</div>
			</IonContent>
			<IonToast
				position="top"
				isOpen={errorMessage !== null}
				message={errorMessage}
				color="secondary"
				duration={2000}
				onDidDismiss={() => {
					setErrorMessage(null);
				}}></IonToast>
		</IonPage>
	);
};

export default HostPartyInfoPage;
