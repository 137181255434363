import {
	IonAvatar,
	IonButtons,
	IonHeader,
	IonMenuButton,
	IonTitle,
	IonToolbar,
} from '@ionic/react';

import { BiMessageSquareDetail } from 'react-icons/bi';
import { useHistory } from 'react-router';
import { useState } from 'react';

const Header = () => {
	const [userData] = useState(
		JSON.parse(localStorage.getItem('userData'))?.data
	);

	const router = useHistory();

	return (
		<IonHeader className="">
			<IonToolbar
				color="primary"
				className="px-2 pb-1.5 mt-1 fr-center-full">
				<IonTitle className="text-otto-tertiary dark:text-otto-tertiary-dark text-3xl line-through">
					OTTO
				</IonTitle>
				<IonButtons slot="start">
					<IonMenuButton menu="side-menu">
						<IonAvatar className="fc-center-center-full">
							{userData?.profile_image ? (
								<img
									src={userData.profile_image}
									alt="profile_image"
									className="h-8 w-8 object-cover rounded-full"
								/>
							) : (
								<div className="bg-otto-secondary dark:bg-otto-secondary-dark h-8 w-8 rounded-full"></div>
							)}
						</IonAvatar>
					</IonMenuButton>
				</IonButtons>
			</IonToolbar>
		</IonHeader>
	);
};

export default Header;
