import { IonContent, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import PartyModal from '../../components/General/Modals/PartyModal';

import BackArrow from '../../components/General/Navigation/BackArrow';
import BackgroundHeader from '../../components/General/UI/BackgroundHeader';
import fetchPartyInvites from '../../data/party/invites/fetchPartyInvites';

const MyPartiesPage = ({ match }) => {
	const [parties, setParties] = useState(null);

	useEffect(() => {
		fetchPartyInvites().then((data) => {
			if (data.data.status !== 'error') {
				setParties(data.data.newArray);
				console.log(parties);
			}

			console.log('WHUY THE FUCK', data);
		});
	}, []);

	return (
		<IonPage>
			<BackgroundHeader />
			<IonContent fullscreen color="primary">
				
				<div className="fc-center-full-full justify-evenly relative">
					<BackArrow RouteLocation="/home" />
				</div>
				
				<div>
					hhhh
				{parties?.map((id, ix)=>{
					return (<PartyModal data={id}></PartyModal>)
					
				})}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default MyPartiesPage;
