import fetch from 'unfetch';
/**
 * USED TO ADD SONG TO VIRTUAL QUEUE
 * @param {*} partyId
 * @param {*} song_name
 * @param {*} song_image
 * @param {*} trackId  THE SONG URI
 * @returns
 */
const requestQueue = async (
	partyId,
	song_name,
	song_image,
	trackId,
	artist_name
) => {
	const url = `/api/spotify/addqueue`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			partyId: partyId,
			song_name: song_name,
			song_image: song_image,
			trackId: trackId,
			artist_name,
		}),
	});

	if (res.ok) {
		return { status: 'succ' };
	} else {
		return { status: 'error' };
	}
};
export default requestQueue;
