import { useRef, useState } from 'react';

import { FormatPhoneNumber } from '../../../../utils/FormatData';
import { IonInput } from '@ionic/react';

const MdTelInput = ({ SetState, isMd, AdditionalTW }) => {
	const [isInputFocused, setInputFocusStatus] = useState(false);

	const phoneInput = useRef();

	const ReformatPhoneNumber = () => {
		let formattedPhoneNumber = FormatPhoneNumber(phoneInput.current.value);
		phoneInput.current.value = formattedPhoneNumber;
		SetState(formattedPhoneNumber.replace(/[^\d]/g, ''));
	};

	return (
		<IonInput
			inputmode="tel"
			className={`${AdditionalTW} + 
			${
				isInputFocused
					? isMd
						? 'input-text-focused-md'
						: 'input-text-focused-full'
					: isMd
					? 'input-text-md'
					: 'input-text-full'
			}
		`}
			maxlength={14}
			ref={phoneInput}
			onIonChange={ReformatPhoneNumber}
			type="tel"
			placeholder="Phone Number"
			onIonFocus={() => {
				setInputFocusStatus(true);
			}}
			onIonBlur={() => {
				setInputFocusStatus(false);
			}}
		/>
	);
};

export default MdTelInput;
