import React, { useEffect, useState } from 'react';

import maplibre from 'maplibre-gl';
import { useLocation } from 'react-router';

function GuestMap({ latitude, longitude }) {
	let mapContainer;

	const location = useLocation();

	useEffect(() => {
		const myAPIKey = '29b60884911b4a30bde2ed2e996be435';
		const mapStyle =
			'https://maps.geoapify.com/v1/styles/osm-carto/style.json';

		const initialState = {
			lat: 20, //TODO: Nick Needs to be dynamic
			lng: 20,
			zoom: 12,
			minZoom: 8,
			maxZoom: 14,
		};

		// const map = new maplibre.Map({
		// 	container: mapContainer,
		// 	style: `${mapStyle}?apiKey=${myAPIKey}`,
		// 	center: [initialState.lng, initialState.lat],
		// 	zoom: initialState.zoom,
		// 	minZoom: initialState.minZoom,
		// 	maxZoom: initialState.maxZoom,
		// });
	}, [mapContainer, longitude, latitude]);

	return (
		<div className="map-container" ref={(el) => (mapContainer = el)}></div>
	);
}

export default GuestMap;
