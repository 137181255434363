import { IonContent, IonPage, IonToast } from '@ionic/react';

import BackArrow from '../../components/General/Navigation/BackArrow';
import BackgroundHeader from '../../components/General/UI/BackgroundHeader';
import MdLoadingButton from '../../components/General/UI/MdLoadingButton';
import MdTelInput from '../../components/General/UI/Forms/Md/MdTelInput';
import { VerifyPhoneNumber } from '../../components/utils/FormInputVerification';
import { useState } from 'react';

const ForgotPasswordPage = () => {
	const [isLoading, setLoadingStatus] = useState(false);
	const [error, setError] = useState(null);
	const [phoneNumber, setPhoneNumber] = useState(null);

	const HandleSendCode = () => {
		let phoneRes = VerifyPhoneNumber(phoneNumber);
		if (phoneRes !== true) {
			setError(phoneRes);
			return false;
		}
		console.log('SMS WOULD BE SENT');
		setLoadingStatus(true);
	};

	return (
		<IonPage>
			<BackgroundHeader />
			<IonContent scrollY={false} color="primary" fullscreen>
				<div className="fc-center-full-full justify-evenly relative">
					<BackArrow />
					<div className="text-7xl line-through">OTTO</div>
					<div className="fc-center w-full">
						<div className="text-center text-sm text-otto-tertiary mb-4 w-3/4">
							*Enter your phone number and instructions will be
							sent to you
						</div>
						<MdTelInput isMd={true} SetState={setPhoneNumber} />
					</div>
					<MdLoadingButton
						ClickFunction={HandleSendCode}
						isLoading={isLoading}
						Message="Send"
					/>
				</div>
			</IonContent>
			<IonToast
				position="top"
				isOpen={error !== null}
				message={`ERROR MESSAGE: ${error}`}
				color="secondary"
				duration={2000}
				onDidDismiss={() => {
					setError(null);
				}}></IonToast>
		</IonPage>
	);
};

export default ForgotPasswordPage;
