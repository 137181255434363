import { useRef, useState } from 'react';

import { IonInput } from '@ionic/react';

const MdTextInput = ({ AdditionalTW, SetState, Placeholder, isMd }) => {
	const [isInputFocused, setInputFocusStatus] = useState(false);

	const textInput = useRef();

	const HandleChange = () => {
		SetState(textInput.current.value);
	};

	return (
		<IonInput
			ref={textInput}
			type="text"
			placeholder={Placeholder || 'PH'}
			className={`
			${
				isInputFocused
					? isMd
						? 'input-text-focused-md'
						: 'input-text-focused-full'
					: isMd
					? 'input-text-md'
					: 'input-text-full'
			} ${AdditionalTW}
		`}
			onIonFocus={() => {
				setInputFocusStatus(true);
			}}
			onIonBlur={() => {
				setInputFocusStatus(false);
			}}
			onIonChange={HandleChange}></IonInput>
	);
};

export default MdTextInput;
