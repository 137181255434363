import fetch from 'unfetch';

const hasRefreshToken = async () => {
	const url = `/api/spotify/hasrefreshtoken`;
	const res = await fetch(url, {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
	});

	if (res.ok) {
		let h = await res.json();
		return true;
	} else {
		return false;
	}
};
export default hasRefreshToken;
