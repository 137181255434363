import {
	IonContent,
	IonPage,
	IonSlide,
	IonSlides,
	IonToast,
} from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import BackArrow from '../../components/General/Navigation/BackArrow';
import { BiSleepy } from 'react-icons/bi';
import ConfirmationModal from '../../components/General/Modals/ConfirmationModal';
import GPAboutSlide from '../../components/Party/GuestParty/GPAboutSlide';
import GPHomeSlide from '../../components/Party/GuestParty/GPHomeSlide';
import GPHostsSlide from '../../components/Party/GuestParty/GPHostsSlide';
import ModalOutline from '../../components/General/Modals/ModalOutline';
import ReportProblemModalBody from '../../components/General/Modals/ReportProblemModalBody';
import SpotifySearchModalBody from '../../components/General/Modals/Spotify/SpotifySearchModalBody';
import SpotifySongModal from '../../components/General/Modals/Spotify/SpotifySongModal';
import UserModalBody from '../../components/General/Modals/UserModalBody';
import { useHistory } from 'react-router';
import fetchPartyInfo from '../../data/party/fetchPartyInfo';
const GuestPartyInfoPage = ({ match }) => {
	const [partyData, setPartyData] = useState(null);
	const [activeSlideIndex, setActiveSlideIndex] = useState(1);
	const [isUserModalActive, setUserModalStatus] = useState(false);
	const [userModalData, setUserModalData] = useState(null);
	const [isReportProblemModalActive, setReportProblemModalStatus] =
		useState(false);
	const [isSpotifySearchModalActive, setSpotifySearchModalStatus] =
		useState(false);
	const [isSpotifySongModalActive, setSpotifySongModalStatus] =
		useState(false);
	const [isConfirmationModalActive, setConfirmationModalStatus] =
		useState(false);
	const [isBackButtonActive, setBackButtonStatus] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [spotifySongModalData, setSpotifySongModalData] = useState(null);
	const [confirmFunction1, setConfirmFunction1] = useState(null);
	const [confirmFunction2, setConfirmFunction2] = useState(null);

	const router = useHistory();

	const slides = useRef(null);
	const [userData] = useState(
		JSON.parse(localStorage.getItem('userData'))?.data
	);

	useEffect(() => {
		if (!userData) {
			router.push('/joinparty/' + match.params.partyId);
		}
		fetchPartyInfo(match.params.partyId).then((data) => {
			console.log(data?.data);
			if (data?.data[0]) {
				let d2;
				let newArr = [];
				newArr.push(data.data[0].location.address);
				newArr.push(data.data[0].location.longitude);
				newArr.push(data.data[0].location.latitude);
				d2 = data.data[0];
				d2.location = newArr;

				setPartyData(d2);
			}
		});
	}, []);

	const slideOptions = {
		initialSlide: 1,
		speed: 400,
		pagination: true,
	};

	const SlideChanged = () => {
		slides?.current?.getActiveIndex().then((index) => {
			setActiveSlideIndex(index);
		});
	};

	const SetSlides = (index) => {
		slides?.current?.slideTo(index);
		setActiveSlideIndex(index);
	};

	const SetUserModalStatus = (user) => {
		setUserModalData(user);
		setUserModalStatus(true);
	};

	const BB_Action = () => {
		setSpotifySongModalStatus(false);
		setSpotifySearchModalStatus(true);
	};

	const BB_Action_2 = () => {
		setConfirmationModalStatus(false);
		setReportProblemModalStatus(true);
	};

	const setSpotifySongModalActive = (songData, isBack) => {
		setSpotifySongModalData(songData);
		setBackButtonStatus(isBack);
		setSpotifySongModalStatus(true);
	};

	function sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	const setConfirmationModalActive = (YesFunction, NoFunction) => {
		setConfirmFunction1(() => YesFunction);
		setConfirmFunction2(() => NoFunction);
		console.log(confirmFunction1);
		console.log('type:', typeof confirmFunction1);
		console.log(confirmFunction2);
		console.log('type:', typeof confirmFunction2);
		setConfirmationModalStatus(true);
		setReportProblemModalStatus(false);
	};

	const SetSpotifySearchModalStatus = (status) => {
		console.log('CALLED', status);
	};

	return (
		<IonPage>
			<IonContent scrollY={false} fullscreen color="primary">
				<BackArrow
					AdditionalTW="mix-blend-difference text-white z-50"
					RouteLocation="/home"
				/>
				{isUserModalActive ? (
					<ModalOutline
						SetState={setUserModalStatus}
						ModalType={UserModalBody}
						ModalData={userModalData}
					/>
				) : null}
				{isSpotifySongModalActive ? (
					<ModalOutline
						SetState={setSpotifySongModalStatus}
						ModalType={SpotifySongModal}
						ModalData={spotifySongModalData}
						BackButtonStatus={isBackButtonActive}
						BackButtonAction={BB_Action}
					/>
				) : null}
				{isSpotifySearchModalActive ? (
					<ModalOutline
						SetState={setSpotifySearchModalStatus}
						SetSecondState={setSpotifySongModalActive}
						ModalType={SpotifySearchModalBody}
					/>
				) : null}

				{isReportProblemModalActive ? (
					<ModalOutline
						SetState={setReportProblemModalStatus}
						ModalType={ReportProblemModalBody}
						SetSecondState={setErrorMessage}
						SetThirdState={setConfirmationModalActive}
					/>
				) : null}
				{isConfirmationModalActive ? (
					<ModalOutline
						SetState={setConfirmationModalStatus}
						ModalType={ConfirmationModal}
						BackButtonAction={BB_Action_2}
						BackButtonStatus={true}
						DynamicMessage={
							'Submitting an SOS call will notify all party hosts that there is an emergency'
						}
						DynamicFunction1={confirmFunction1}
						DynamicFunction2={confirmFunction2}
						// SetSecondState={setErrorMessage}
						SetThirdState={false}
					/>
				) : null}
				<div className="fc-center-full-full">
					{partyData?.party_image ? (
						<div className="w-full h-1/3 relative">
							<div className="modal-gradient"></div>
							<img
								src={partyData?.party_image}
								alt="party_image"
								className="w-full h-full object-cover"
							/>
						</div>
					) : (
						<div className="w-full h-1/3 bg-gradient-to-b rounded-t-lg from-otto-primary dark:from-otto-primary-dark" />
					)}
					<div className="text-xl font-semibold -mt-4 z-30">
						{partyData?.party_name || 'Party'}
					</div>
					<div className="w-full flex flex-row justify-around items-center mt-1 border-b border-otto-tertiary dark:border-otto-tertiary-dark pb-1">
						<div
							onClick={() => {
								SetSlides(0);
							}}
							className={`${
								activeSlideIndex === 0
									? 'text-otto-tertiary dark:text-otto-tertiary-dark'
									: null
							}`}>
							Hosts
						</div>
						<div
							onClick={() => {
								SetSlides(1);
							}}
							className={`${
								activeSlideIndex === 1
									? 'text-otto-tertiary dark:text-otto-tertiary-dark'
									: null
							}`}>
							Home
						</div>
						<div
							onClick={() => {
								SetSlides(2);
							}}
							className={`${
								activeSlideIndex === 2
									? 'text-otto-tertiary dark:text-otto-tertiary-dark'
									: null
							}`}>
							About
						</div>
					</div>
					<IonSlides
						ref={slides}
						onIonSlideDidChange={SlideChanged}
						pager={true}
						options={slideOptions}
						className="w-full overflow-x-hidden h-2/3">
						<IonSlide>
							<GPHostsSlide
								SetPopupState={SetUserModalStatus}
								HostData={partyData?.hosts}
							/>
						</IonSlide>
						<IonSlide>
							<GPHomeSlide
								guestData={partyData?.guests}
								SetReportModalState={
									setReportProblemModalStatus
								}
								SetSpotifySearchModalStatus={
									setSpotifySearchModalStatus
								}
							/>
						</IonSlide>
						<IonSlide>
							<GPAboutSlide
								partyData={partyData}
								Slides={slides}
							/>
						</IonSlide>
					</IonSlides>
				</div>
			</IonContent>
			<IonToast
				position="top"
				isOpen={errorMessage !== null}
				message={errorMessage}
				color="secondary"
				duration={2000}
				onDidDismiss={() => {
					setErrorMessage(null);
				}}></IonToast>
		</IonPage>
	);
};

export default GuestPartyInfoPage;
