export const VerifyPhoneNumber = (phoneNumber) => {
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	if (!phoneNumber) {
		return 'Please enter a valid phone number';
	} else if (phoneNumber.length < 10) {
		return 'Phone number must be 10 numbers long';
	} else if (phoneRegExp.test(phoneNumber)) {
		return true;
	} else {
		return 'Invalid Phone Number';
	}
};

export const VerifyPasswords = (password, vpassword) => {
	if (!password) {
		return 'Please enter a valid password';
	} else if (password.length < 4) {
		return 'Password must be at least 4 characters long';
	} else if (password.length > 20) {
		return 'Password must not exceed 20 characters';
	} else if (password !== vpassword) {
		return 'Password fields must match';
	} else {
		return true;
	}
};

export const VerifySMSPin = (pin) => {
	let isNum = /^\d+$/.test(pin);
	if (isNum) {
		if (pin.length === 4) {
			return true;
		} else {
			return 'Please enter valid pin';
		}
	} else {
		return 'Please enter valid pin';
	}
};

export const VerifyPartyName = (partyName) => {
	if (!partyName) {
		return 'Please enter a valid party name';
	} else if (partyName.length > 30) {
		return 'Party name must not exceed 30 characters';
	} else {
		return true;
	}
};

export const VerifyStartDate = (date) => {
	let startDate = new Date(date);
	let currentDate = new Date();
	if (!date) {
		return 'Please enter a valid start date';
	} else if (startDate < currentDate) {
		return 'Start date must be after current date';
	} else {
		return true;
	}
};

export const VerifyDuration = (duration) => {
	if (!duration) {
		return 'Please enter a valid duration';
	} else {
		return true;
	}
};

export const VerifyPartyImage = (image) => {
	if (!image) {
		return 'Please enter a valid party photo';
	} else {
		return true;
	}
};

export const VerifyDescription = (description) => {
	if (!description) {
		return 'Please enter a valid party description';
	} else if (description?.length > 150) {
		return 'Party description must not exceed 150 characeters';
	} else {
		return true;
	}
};
