import fetch from 'unfetch';

const getPlaylists = async (access_token) => {
	console.log('getting called', access_token);
	const url = `/api/spotify/getPlaylists`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({ access_token: access_token }),
	});

	if (res.ok) {
		let h = await res.json();
		return await h;
	} else {
		return false;
	}
};
export default getPlaylists;
