import { useState, useEffect } from 'react';
import fetch from 'unfetch';
import updatePartyToken from './updateParties';
const signoutSpotify = async () => {
	const url = `/api/spotify/signout`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
	});

	if (res.ok) {
		let h = await res.json();
       await updatePartyToken(null)
		return await h;
	} else {
        let h = await res.json();
        console.log(h)
		return false;
	}
};
export default signoutSpotify;
