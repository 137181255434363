import { AiOutlineCheckCircle, AiOutlineEllipsis } from 'react-icons/ai';
import { IonButton, IonPopover } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';

// import { FriendContext } from '../../../App';
import MdLoadingButton from '../UI/MdLoadingButton';
import OutsideClickHandler from 'react-outside-click-handler';
import sendFriendRequest from '../../../data/friends/sendFriendRequest';
import unfriend from '../../../data/friends/unfriend';

const UserModalBody = ({ data, SetState, SetSecondState, SetThirdState }) => {
	const [isFollowing, setFollowingStatus] = useState(false);
	const [isRequestIssued, setRequestIssuedStatus] = useState(false);
	const [isPopoverActive, setPopoverStatus] = useState(false);
	const [isLoading, setLoadingStatus] = useState(false);

	// const friendData = useContext(FriendContext);

	// useEffect(() => {
	// 	console.log('FCONTEXT DATA', friendData['friends']);
	// 	console.log('FCONTEXT ISS DATA', friendData['issued_requests']);

	// 	setFollowingStatus(
	// 		friendData['friends'].some((id) => id.UUID === data.UUID)
	// 	);

	// 	setRequestIssuedStatus(
	// 		friendData['issued_requests'].some((id) => id.UUID === data.UUID)
	// 	);
	// }, [friendData]);

	const HandleFollow = () => {
		setLoadingStatus(true);
		sendFriendRequest(data.username).then((resp) => {
			if (resp.status === 'succ') {
				setLoadingStatus(false);
				setRequestIssuedStatus(true);
			} else {
				setLoadingStatus(false);
			}
		});

		// TODO: Isaiah figure out how to show the data change on the front end
	};
	const HandleUnfollow = () => {
		// console.log('Unfollowed', data?.UUID);

		unfriend(data?.UUID).then((resp) => {
			if (resp.status === 'succ') {
				setFollowingStatus(false);
			} else {
				console.log('TODO');
			}
		});
		SetSecondState(`${data?.firstName} ${data?.lastName} unfollowed`);
		setPopoverStatus(false);
	};
	const ConfirmCancel = () => {
		// console.log('Cancelled', data?.UUID);
		SetSecondState('Unfollow Cancelled');
		setPopoverStatus(false);
	};

	return (
		<div className="fc-center-full-full justify-between my-4">
			<div className="fc-center-full">
				{data?.profile_image ? (
					<img
						src={data?.profile_image}
						alt="profile_img"
						className="h-24 w-24 rounded-full object-cover"
					/>
				) : (
					<div className="h-24 w-24 bg-otto-primary dark:bg-otto-primary-dark rounded-full mt-4 fr-center-center-full text-2xl font-medium">
						{data?.firstName.slice(0, 1)}
						{data?.lastName.slice(0, 1)}
					</div>
				)}
				<div className="text-lg mt-1">
					{data?.firstName} {data?.lastName}
				</div>
				<div className="subtext">@{data?.username}</div>
				<div>
					{isPopoverActive ? (
						<OutsideClickHandler
							onOutsideClick={() => {
								setPopoverStatus(false);
								console.log('Popover Closed');
							}}>
							<div className="fc-center-full bg-otto-primary dark:bg-otto-primary-dark divide-y divide-otto-secondary dark:divide-otto-secondary-dark text-center rounded-md shadow-lg mt-6 relative">
								<div class="w-7 absolute overflow-hidden inline-block mx-auto -top-4">
									<div class="h-5 w-5 bg-otto-primary dark:bg-otto-primary-dark rotate-45 transform origin-bottom-left"></div>
								</div>
								{/* <div
									className="w-full p-2 py-1"
									onClick={() => {
										SetThirdState(
											HandleUnfollow,
											ConfirmCancel
										);
									}}>
									Unfollow User
								</div> */}
								<div
									className="w-full p-2 py-1"
									onClick={() => {
										setPopoverStatus(false);
									}}>
									Close
								</div>
							</div>
						</OutsideClickHandler>
					) : null}
				</div>
				{isFollowing && !isPopoverActive ? (
					<AiOutlineEllipsis
						className="text-2xl"
						onClick={(e) => {
							e.persist();
							setPopoverStatus(true);
						}}
					/>
				) : null}
			</div>
			
			{/* {!isFollowing && !isRequestIssued ? (
				<MdLoadingButton
					ClickFunction={HandleFollow}
					isLoading={isLoading}
					Message="Follow"
					AdditionalTW="py-2 w-1/2"
				/>
			) : isRequestIssued ? (
				<AiOutlineCheckCircle className="text-5xl text-otto-tertiary dark:text-otto-tertiary-dark" />
			) : null} */}
		</div>
	);
};

export default UserModalBody;
