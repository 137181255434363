import './styles/main.css';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';

import { IonApp, IonRouterOutlet } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import ChangePasswordPage from './pages/Settings/ChangePasswordPage';
import CreatePartyPage from './pages/Party/CreatePartyPage';
import EventsPage from './pages/Main/EventsPage';
import FetchFriendInfoSWR from './data/swr/FetchFriendsSWR';
import FetchPartyInvitesSWR from './data/swr/FetchPartyInvitesSWR';
import ForgotPasswordPage from './pages/Landing/ForgotPasswordPage';
import FriendsPage from './pages/Main/FriendsPage';
import GuestPartyInfoPage from './pages/Party/GuestPartyInfoPage';
import HomePage from './pages/Main/HomePage';
import HostPartyInfoPage from './pages/Party/HostPartyInfoPage';
import { IonReactRouter } from '@ionic/react-router';
import JoinPartyPage from './pages/Party/JoinPartyPage';
import LandingPage from './pages/Landing/LandingPage';
import LoginPage from './pages/Landing/LoginPage';
import MyPartiesPage from './pages/Party/MyPartiesPage';
import ProfilePage from './pages/Main/ProfilePage';
import SettingsPage from './pages/Main/SettingsPage';
import SideMenu from './components/General/Navigation/SideMenu';
import SignUpPage from './pages/Landing/SignUpPage';
import RSVPPartyPage from './pages/Party/RSVPPage';
import { createAnimation } from '@ionic/core';
import fetchPartyInvites from './data/party/invites/fetchPartyInvites';
import getFriendInfo from './data/friends/getFriendsInfo';
import useSWR from 'swr';
import SpotifyIntegrationPage from './pages/Main/SpotifyIntegration';
import SpotifyConfigurationModalBody from './components/General/Modals/Spotify/SpotifyConfigurationModalBody';
export const PartyContext = React.createContext();
export const FriendContext = React.createContext();

// TODO:
// Add timeout to API loading shit

const App = () => {
	// const [partyData, setPartyData] = useState(null);

	// const [friendData, setFriendData] = useState(null);
	// const [array_req, setArray_Req] = useState(null);
	// const [array_iss, setArray_Iss] = useState(null);
	// const [array_friends, setArray_Friends] = useState(null);

	// let dataPartyInvites = FetchPartyInvitesSWR();
	// //let friendInfoNew = FetchFriendInfoSWR('friends');
	// let friendRequests = FetchFriendInfoSWR('pending_requests');

	// useEffect(() => {
	// 	console.log('here in get');
	// 	getFriendInfo('friends').then((data) => {
	// 		console.log('GET FRIENDS', data);
	// 		setArray_Friends(data?.data?.data);
	// 	});
	// 	getFriendInfo('issued_requests').then((data) => {
	// 		setArray_Iss(data?.data?.data);
	// 	});

	// 	// getFriendInfo('pending_requests').then((data) => {
	// 	// 	setArray_Req(data?.data?.data);
	// 	// });
	// }, [array_req]);

	// useEffect(() => {
	// 	friendRequests.then((data) => {
	// 		console.log(data);
	// 		if (data?.status !== 'error') {
	// 			setArray_Req(data?.data);
	// 		} else {
	// 			console.log('Not chill');
	// 			// nfFriendArr['friends'] = null;
	// 		}
	// 	});
	// }, [friendRequests]);

	// useEffect(() => {
	// 	pending_friend_requests.then((data) => {
	// 		console.log(data);
	// 		if (data?.status !== 'error') {
	// 			setArray_Iss(data?.data);
	// 			console.log('HELLL YEAAAA ISSUED', data);
	// 		} else {
	// 			console.log('Not chill');
	// 			// nfFriendArr['friends'] = null;
	// 		}
	// 	});
	// }, [pending_friend_requests]);

	// useEffect(() => {
	// 	console.log('in array friends');
	// 	console.log(array_friends, '\n', array_iss, '\n', array_req);
	// 	let friendArray = [];
	// 	friendArray['friends'] = array_friends;
	// 	friendArray['pending_requests'] = array_req;
	// 	friendArray['issued_requests'] = array_iss;
	// 	setFriendData(friendArray);
	// }, [array_friends, array_req, array_iss]);

	// useEffect(() => {
	// 	dataPartyInvites.then((data) => {
	// 		setPartyData(data?.newArray);
	// 	});
	// }, [dataPartyInvites]);

	useEffect(() => {
		if (
			window.matchMedia &&
			window.matchMedia('(prefers-color-scheme: dark)').matches
		) {
			document.documentElement.classList.add('dark');
		} else {
			document.documentElement.classList.add('light');
		}
	}, [window.matchMedia]);

	const animationBuilder = (baseEl, opts) => {
		const enteringAnimation = createAnimation()
			.addElement(opts.enteringEl)
			.fromTo('opacity', 0, 1)
			.duration(0);

		const leavingAnimation = createAnimation()
			.addElement(opts.leavingEl)
			.fromTo('opacity', 1, 0)
			.duration(0);

		const animation = createAnimation()
			.addAnimation(enteringAnimation)
			.addAnimation(leavingAnimation);

		return animation;
	};

	return (
		<IonApp>
			{/* <PartyContext.Provider value={partyData}>
				<FriendContext.Provider value={friendData}> */}
			<IonReactRouter>
				<SideMenu />
				<IonRouterOutlet animation={animationBuilder}>
					<Route exact path="/">
						<Redirect to="/welcome" />
					</Route>
					<Route exact path="/welcome" component={LandingPage} />
					<Route exact path="/login" component={LoginPage} />
					<Route exact path="/signup" component={SignUpPage} />
					<Route
						exact
						path="/forgotpassword"
						component={ForgotPasswordPage}
					/>
					<Route render={() => <Redirect to={'/welcome'} />} />
					<Route exact path="/joinparty" component={JoinPartyPage} />
					{/* <Route
								exact
								path="/spotify"
								component={SpotifyConfigurationModalBody}
							/> */}
					<Route
						exact
						path="/joinparty/:pid"
						component={JoinPartyPage}
					/>
					<Route exact path="/RSVP/:pid" component={RSVPPartyPage} />

					<Route exact path="/home" component={HomePage} />
					<Route exact path="/profile" component={ProfilePage} />
					{/* <Route
								exact
								path="/friends"
								component={FriendsPage}
							/> */}
					<Route
						exact
						path="/party/:partyId"
						component={GuestPartyInfoPage}
					/>
					<Route
						exact
						path="/party/:partyId/host"
						component={HostPartyInfoPage}
					/>
					<Route exact path="/settings" component={SettingsPage} />
					<Route
						exact
						path="/createparty"
						component={CreatePartyPage}
					/>
					{/* <Route
								exact
								path="/events"
								component={EventsPage}
							/> */}
					<Route
						exact
						path="/myparties/:phoneNumber"
						component={MyPartiesPage}
					/>
					<Route
						exact
						path="/changepassword"
						component={ChangePasswordPage}
					/>
				</IonRouterOutlet>
			</IonReactRouter>
			{/* </FriendContext.Provider>
			</PartyContext.Provider> */}
		</IonApp>
	);
};

export default App;
