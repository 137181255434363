import { CgSpinnerTwo } from 'react-icons/cg';

const MdLoadingButton = ({
	ClickFunction,
	isLoading,
	Message,
	AdditionalTW,
}) => {
	return (
		<div
			onClick={() => {
				if (!isLoading) {
					ClickFunction();
				}
			}}
			className={`p-4 ${
				isLoading ? 'md-button-loading' : 'md-button'
			} + ${AdditionalTW}`}>
			{isLoading ? <CgSpinnerTwo className="animate-spin" /> : Message}
		</div>
	);
};

export default MdLoadingButton;
