import '@geoapify/geocoder-autocomplete/styles/round-borders-dark.css';

import {
	GeoapifyContext,
	GeoapifyGeocoderAutocomplete,
} from '@geoapify/react-geocoder-autocomplete';
import React, { useEffect, useRef, useState } from 'react';

import { IonInput } from '@ionic/react';
import MdTextInput from '../General/UI/Forms/Md/MdTextInput';
import autofillLocation from '../../data/party/autofillLocation';

const LocationAutofill = ({ SetState }) => {
	const [locationResults, setLocationResults] = useState(null);
	const [locationInputText, setLocationInputText] = useState(null);
	const [isLocationInputFocused, setLocationInputFocusStatus] =
		useState(false);
	const [shouldSearch, setShouldSearchStatus] = useState(true);

	const locationInput = useRef(null);

	var typingTimer;
	var doneTypingInterval = 500;

	const StartCountdown = () => {
		if (shouldSearch) {
			clearTimeout(typingTimer);
			if (locationInput.current.value) {
				typingTimer = setTimeout(HandleInputChange, doneTypingInterval);
			}
		} else {
			setShouldSearchStatus(true);
		}
	};

	const HandleInputChange = () => {
		setTimeout(function () {
			autofillLocation(locationInput.current.value).then((resp) => {
				let locationArray = [];
				locationArray = resp?.features;
				setLocationResults(locationArray);
			});
		}, 500);
	};

	return (
		<div className="fc-center-full mt-2">
			<IonInput
				ref={locationInput}
				type="text"
				placeholder="Location"
				className={`${
					isLocationInputFocused
						? 'input-text-focused-full'
						: 'input-text-full'
				}`}
				onIonFocus={() => {
					setLocationInputFocusStatus(true);
				}}
				onIonBlur={() => {
					setLocationInputFocusStatus(false);
				}}
				onIonChange={StartCountdown}
			/>
			<div className="fc-full divide-y divide-otto-secondary dark:divide-otto-secondary-dark overflow-y-scroll">
				{locationResults
					? locationResults.map((item, idx) => {
							console.log('INDIV ITEM', item);
							return (
								<div
									onClick={() => {
										setShouldSearchStatus(false);
										locationInput.current.value = `${item?.properties?.address_line1} ${item?.properties?.address_line2}`;
										SetState({
											address: `${item?.properties?.address_line1}, ${item?.properties?.address_line2}`,
											longitude: item?.properties?.lon,
											latitude: item?.properties?.lat,
										});
										setLocationResults(null);
										locationInput.current.blur();
									}}
									key={idx}
									className="w-full text-xs p-2 truncate">
									<span className="font-semibold">
										{item?.properties?.address_line1 ||
											null}
									</span>
									{' , '}
									{item?.properties?.address_line2 || null}
								</div>
							);
					  })
					: null}
			</div>
		</div>
	);
};

export default LocationAutofill;
