import fetch from 'unfetch';
/**
 * 
 * @param {*} user 
 * 
 * password: user.password,
			firstName: user.firstName,
			lastName: user.lastName,
			phoneNumber: user.phoneNumber,
			username: user.username,
			gender: user.gender,
			isHost: user.isHost,
 * 
 * 
 * @returns 
 */
const SubmitSignup = async (user) => {
	const url = `/api/signup/Signup`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			password: user.password,
			firstName: user.firstName,
			lastName: user.lastName,
			username: user.username,
			gender: user.gender,
		}),
	});

	if (res.ok) {
		localStorage.clear();
		// let responseData = JSON.parse(JSON.stringify(await res.json()));
		// localStorage.setItem('userData', JSON.stringify(await res.json()));
		// localStorage.setItem('auth', responseData.jwt);
		return 0;
	} else {
		console.log('submitSignup ERROR');
		return 1;
	}
};

export default SubmitSignup;
