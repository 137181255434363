import {
	IonAvatar,
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenu,
	IonNote,
	IonRouterOutlet,
	IonSkeletonText,
	IonToolbar,
} from '@ionic/react';
import { useEffect, useState } from 'react';

import { AiOutlineClose } from 'react-icons/ai';
import { BiLogOut } from 'react-icons/bi';
import { BsChevronRight } from 'react-icons/bs';
import { CgEnter } from 'react-icons/cg';
import { RiMessageLine } from 'react-icons/ri';
import { VscAdd } from 'react-icons/vsc';
import logout from '../../../data/UserAuth/logout';
import { menuController } from '@ionic/core';
import { setDefaultHandler } from 'workbox-routing';
import { useHistory } from 'react-router';

const SideMenu = () => {
	const router = useHistory();
	const [userData] = useState(
		JSON.parse(localStorage.getItem('userData'))?.data
	);

	const Party = [
		{
			name: 'Create Party',
			icon: <VscAdd className="side-menu-icon ml-1 mr-1" />,
			clickFunction: function pushRoute() {
				menuController.toggle();
				router.push('/createparty');
			},
		},
		{
			name: 'Join Party',
			icon: <CgEnter className="side-menu-icon" />,
			clickFunction: function pushRoute() {
				menuController.toggle();
				router.push('/joinparty');
			},
		},
	];

	const Features = [
		{
			name: 'Messaging',
			icon: <RiMessageLine className="side-menu-icon" />,
			clickFunction: function pushRoute() {
				menuController.toggle();
			},
			note: 'XX',
		},
	];

	const Account = [
		{
			name: 'Sign Out',
			icon: <BiLogOut className="side-menu-icon" />,
			clickFunction: function Logout() {
				logout();
			},
		},
	];

	const MenuOptions = [Party, Features, Account];
	const MenuOptionStrings = ['Party', 'Features', 'Account'];

	const HandleCPClick = () => {
		menuController.toggle();
		router.push('/createparty');
	};

	return (
		<IonMenu
			side="start"
			menuId="side-menu"
			contentId="side-main"
			type="push">
			<IonHeader>
				<IonToolbar color="primary">
					<AiOutlineClose className="absolute top-1 left-1 text-3xl" />
					<div className="fc-center-full py-3">
						<div className="fc-center-full">
							{userData?.profile_image ? (
								<img
									src={userData.profile_image}
									alt="profile_image"
									className={`${
										userData ? 'my-1' : 'my-0.5'
									} h-20 w-20 rounded-full object-cover`}
								/>
							) : (
								<div
									className={`${
										userData ? 'my-1' : 'my-0.5'
									} h-20 w-20 rounded-full bg-otto-secondary dark:bg-otto-secondary-dark fr-center-center-full`}>
									{userData?.firstName?.slice(0, 1)}
									{userData?.lastName?.slice(0, 1)}
								</div>
							)}
							<IonLabel>
								{userData ? (
									<div className="text-otto-tertiary dark:text-otto-secondary-dark">
										@{userData.username}
									</div>
								) : (
									<div className="w-20 h-4 skeleton my-1"></div>
								)}
							</IonLabel>
							<div
								onClick={HandleCPClick}
								className="md-button mt-2">
								Create Party
							</div>
						</div>
					</div>
				</IonToolbar>
			</IonHeader>
			<IonContent color="primary">
				<IonList>
					{MenuOptions.map((Header, idx) => {
						return (
							<div key={idx}>
								<div className="p-2 border-b border-otto-tertiary dark:border-otto-tertiary-dark bg-otto-primary dark:bg-otto-primary font-semibold">
									{MenuOptionStrings[idx]}
								</div>
								{Header.map((SubHeader, subIdx) => {
									return (
										<IonItem
											color="primary"
											onClick={SubHeader.clickFunction}
											key={subIdx}>
											{SubHeader.icon}
											<IonLabel>
												{SubHeader.name}
											</IonLabel>
											{SubHeader.note ? (
												<IonNote slot="end">
													{SubHeader.note}
												</IonNote>
											) : null}
										</IonItem>
									);
								})}
							</div>
						);
					})}
				</IonList>
			</IonContent>
			<IonRouterOutlet id="side-main"></IonRouterOutlet>
		</IonMenu>
	);
};

export default SideMenu;
