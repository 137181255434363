import {
	IonContent,
	IonHeader,
	IonImg,
	IonItem,
	IonList,
	IonSearchbar,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';

import searchUsers from '../../../data/friends/searchUsers';

const SearchUserModalBody = ({ SetSecondState, SetState }) => {
	const [userSearchResults, setUserSearchResults] = useState(null);
	const searchbar = useRef(null);
	const list = useRef(null);

	const SearchUsers = (val) => {
		console.log('SB Val', val);
		if (val === '' || val === ' ') {
		} else {
			searchUsers(val).then((resp) => {
				setUserSearchResults(resp.data);
			});
		}
	};

	//searchbar.addEventListener('ionInput', handleInput);

	return (
		<div className="fc-center-full-full">
			<IonHeader translucent color="secondary">
				<IonToolbar color="secondary">
					<IonTitle>Searchbar</IonTitle>
				</IonToolbar>
				<IonToolbar color="secondary">
					<IonSearchbar
						onIonChange={(e) => SearchUsers(e.target.value)}
						ref={searchbar}></IonSearchbar>
				</IonToolbar>
			</IonHeader>
			<IonContent color="secondary">
				<IonList
					onChangeCapture={SearchUsers}
					ref={list}
					color="secondary">
					{userSearchResults
						? userSearchResults.map((item, idx) => {
								return (
									<IonItem
										onClick={() => {
											SetState(false);
											SetSecondState(item, true);
										}}
										color="secondary"
										key={idx}
										className="bg-otto-secondary dark:bg-otto-secondary-dark">
										{item.profile_image ? (
											<div className="h-10 w-10 rounded-full overflow-hidden mr-2">
												<IonImg
													src={item.profile_image}
													alt="profile_img"
													className="h-full w-full object-cover rounded-full"
												/>
											</div>
										) : (
											<div className="h-10 w-10 rounded-full bg-otto-primary dark:bg-otto-primary-dark fr-center-center-full mr-2">
												{item.firstName.slice(0, 1)}
												{item.lastName.slice(0, 1)}
											</div>
										)}
										{item.firstName} {item.lastName}
									</IonItem>
								);
						  })
						: null}
				</IonList>
			</IonContent>
		</div>
	);
};

export default SearchUserModalBody;
