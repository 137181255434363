import { useEffect } from 'react';

const GPHomeSlide = ({
	guestData,
	SetReportModalState,
	SetSpotifySearchModalStatus,
}) => {
	useEffect(() => {}, [guestData]);

	return (
		<div className="flex flex-col w-full h-full">
			<div className="flex-flex-col">
				<div>HOME (Friends attending?)</div>
				<div
					onClick={() => {
						SetSpotifySearchModalStatus(true);
					}}
					className="md-button mx-auto mt-24 py-4 bg-green-500 rounded-full">
					Queue a Song
				</div>
				<div></div>
			</div>
			<div
				onClick={() => {
					SetReportModalState(true);
				}}
				className="border mx-auto mt-24 md-button border-otto-danger dark:border-otto-danger dark:hover:bg-otto-danger hover:bg-otto-danger py-2 bg-red-600">
				Report Problem
			</div>
		</div>
	);
};

export default GPHomeSlide;
