import { IonContent, IonPage } from "@ionic/react";

import BackArrow from "../../components/General/Navigation/BackArrow";
import BackgroundHeader from "../../components/General/UI/BackgroundHeader";

const ProfilePage = () => {
	return (
		<IonPage>
			<BackgroundHeader />
			<IonContent scrollY={false} fullscreen color="primary">
				<div className="fc-center-full-full justify-evenly relative">
					<BackArrow />
				</div>
			</IonContent>
		</IonPage>
	);
};

export default ProfilePage;
