import {
	IonContent,
	IonItem,
	IonLabel,
	IonList,
	IonNote,
	IonPage,
} from '@ionic/react';
import { RiLockPasswordLine, RiMessageLine } from 'react-icons/ri';
import { useEffect, useState } from 'react';

import { BiLogOut } from 'react-icons/bi';
import { BsChevronRight } from 'react-icons/bs';
import { CgEnter } from 'react-icons/cg';
import { FaSpotify } from 'react-icons/fa';
import Footer from '../../components/General/Navigation/Footer';
import Header from '../../components/General/Navigation/Header';
import ModalOutline from '../../components/General/Modals/ModalOutline';
import SpotifyConfigurationModalBody from '../../components/General/Modals/Spotify/SpotifyConfigurationModalBody';
import { VscAdd } from 'react-icons/vsc';
import hasRefreshToken from '../../data/Spotify/hasRefreshToken';
import { useHistory } from 'react-router';
import logout from '../../data/UserAuth/logout';

const SettingsPage = () => {
	const [userData] = useState(
		JSON.parse(localStorage.getItem('userData'))?.data
	);
	const [isLoggedIn, setIsLoggedIn] = useState(null);
	const queryParams = new URLSearchParams(window.location.search);
	const code = queryParams.get('code');
	const [
		isSpotifyConfigurationModalActive,
		setSpotifyConfigurationModalStatus,
	] = useState(false);

	const router = useHistory();

	useEffect(() => {
		//This basically checks to see if there is a code in the url, and if so opens the modal.
		//The modal then handles the rest. Could get a little fucky
		console.log('UD', userData);
		if (code) {
			hasRefreshToken().then((data) => {
				if (!data) {
					setSpotifyConfigurationModalStatus(true);
				}
			});
		}
	}, []);

	const Party = [
		{
			name: 'Create Party',
			icon: <VscAdd className="side-menu-icon ml-1 mr-1" />,
			clickFunction: function pushRoute() {
				router.push('/createparty');
			},
		},
		{
			name: 'Join Party',
			icon: <CgEnter className="side-menu-icon" />,
			clickFunction: function pushRoute() {
				console.log('CLICKED');
			},
		},
	];

	const Features = [
		// {
		// 	name: 'Messaging',
		// 	icon: <RiMessageLine className="side-menu-icon" />,
		// 	clickFunction: function pushRoute() {
		// 		console.log('CLICKED');
		// 	},
		// 	note: 'XX',
		// },
	];

	const Account = [
		{
			name: 'Change Password',
			icon: <RiLockPasswordLine className="side-menu-icon" />,
			clickFunction: function ChangePassword() {
				router.push('/changepassword');
			},
			note: <BsChevronRight />,
		},
		{
			name: 'Sign Out',
			icon: <BiLogOut className="side-menu-icon" />,
			clickFunction: function Logout() {
				logout();
			},
		},
	];

	const Integrations = [
		{
			name: 'Spotify',
			icon: <FaSpotify className="side-menu-icon" />,
			clickFunction: function setSpotifyActive() {
				setSpotifyConfigurationModalStatus(true);
			},
			note: <BsChevronRight />,
		},
	];

	const MenuOptions = [Party, Features, Account, Integrations];
	const MenuOptionStrings = ['Party', 'Features', 'Account', 'Integrations'];

	return (
		<IonPage>
			<Header />
			{isSpotifyConfigurationModalActive ? (
				<ModalOutline
					SetState={setSpotifyConfigurationModalStatus}
					ModalType={SpotifyConfigurationModalBody}
				/>
			) : null}
			<IonContent fullscreen color="primary">
				<IonList color="primary">
					{MenuOptions.map((Header, idx) => {
						return (
							<div key={idx}>
								<div className="p-2 border-b border-otto-tertiary dark:border-otto-tertiary-dark bg-otto-primary dark:bg-otto-primary-dark font-semibold">
									{MenuOptionStrings[idx]}
								</div>
								{Header.map((SubHeader, subIdx) => {
									return (
										<IonItem
											color="primary"
											onClick={SubHeader.clickFunction}
											key={subIdx}>
											{SubHeader.icon}
											<IonLabel>
												{SubHeader.name}
											</IonLabel>
											{SubHeader.note ? (
												<IonNote slot="end">
													{SubHeader.note ||
														SubHeader.subIcon}
												</IonNote>
											) : null}
										</IonItem>
									);
								})}
							</div>
						);
					})}
				</IonList>
			</IonContent>
			<Footer />
		</IonPage>
	);
};

export default SettingsPage;
