import { IonContent, IonPage, IonToast } from "@ionic/react";

import BackArrow from "../../components/General/Navigation/BackArrow";
import BackgroundHeader from "../../components/General/UI/BackgroundHeader";
import MdLoadingButton from "../../components/General/UI/MdLoadingButton";
import MdPasswordInput from "../../components/General/UI/Forms/Md/MdPasswordInput";
import MdTelInput from "../../components/General/UI/Forms/Md/MdTelInput";
import login from "../../data/UserAuth/Login";
import { useHistory } from "react-router";
import { useState } from "react";

const LandingPage = () => {
	const [isLoginSuccess, setLoginSuccessStatus] = useState(null);
	const [loginFailedMessage, setLoginFailedMessage] = useState(null);
	const [isLoginLoading, setLoginLoadingStatus] = useState(null);
	const [phoneNumber, setPhoneNumber] = useState(null);
	const [password, setPassword] = useState(null);

	const router = useHistory();

	const HandleLoginSubmit = () => {
		setLoginLoadingStatus(true);
		if (phoneNumber && password) {
			login(phoneNumber, password).then((resp) => {
				console.log("RESP", resp);
				if (resp.status === "success") {
					router.push("/home");
				} else {
					setLoginFailedMessage(
						"ERROR CODE 0: Invalid login information"
					);
					setLoginSuccessStatus(false);
					setLoginLoadingStatus(false);
				}
			});
		} else {
			setLoginFailedMessage(
				"ERROR CODE 1: Please enter login information"
			);
			setLoginSuccessStatus(false);
			setLoginLoadingStatus(false);
		}
	};

	return (
		<IonPage>
			<BackgroundHeader />
			<IonContent scrollY={false} color="primary" fullscreen>
				<div className="fc-center-full-full justify-evenly relative">
					<BackArrow />

					<div className="text-7xl line-through">OTTO</div>
					<div className="fc-center w-2/3">
						<MdTelInput SetState={setPhoneNumber} />
						<MdPasswordInput
							SetState={setPassword}
							AdditionalTW="mt-6"
						/>
						<div
							onClick={() => {
								router.push("/forgotpassword");
							}}
							className="mt-8 text-sm underline"
						>
							Forgot Password
						</div>
					</div>
					<MdLoadingButton
						ClickFunction={HandleLoginSubmit}
						isLoading={isLoginLoading}
						Message="Login"
					/>
				</div>
			</IonContent>
			<IonToast
				position="top"
				isOpen={isLoginSuccess === false}
				message={loginFailedMessage}
				color="secondary"
				duration={2000}
				onDidDismiss={() => {
					setLoginSuccessStatus(null);
				}}
			></IonToast>
		</IonPage>
	);
};

export default LandingPage;
