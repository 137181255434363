const HostModalBody = ({ data }) => {
	return (
		<div className="fc-center-between-full-full my-4">
			<div className="fc-center-full">
				{data?.profile_image ? (
					<img
						src={data?.profile_image}
						alt="profile_img"
						className="h-24 w-24 rounded-full object-cover"
					/>
				) : (
					<div className="h-24 w-24 bg-otto-primary dark:bg-otto-primary-dark rounded-full mt-4 fr-center-center-full text-2xl font-medium">
						{data?.firstName.slice(0, 1)}
						{data?.lastName.slice(0, 1)}
					</div>
				)}
				<div className="text-lg mt-1">
					{data?.firstName} {data?.lastName}
				</div>
				<div className="subtext">@{data?.username}</div>
			</div>
		</div>
	);
};

export default HostModalBody;
