import { useEffect, useState } from "react";

import fetch from "unfetch";
/**
 *
 * @param {*} phoneNumber The users phone number
 * @param {*} password The users password
 * @returns status:"success" || status:"error"
 */
const login = async (phoneNumber, password) => {
	console.log("PN", phoneNumber);
	const url = `/api/submitLogin`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"content-type": "application/json",
		},
		body: JSON.stringify({
			phoneNumber: phoneNumber,
			password: password,
		}),
	});

	if (res.ok) {
		let responseData = JSON.parse(JSON.stringify(await res.json()));
		localStorage.setItem("userData", JSON.stringify(await res.json()));
		localStorage.setItem("auth", responseData.jwt);

		return { status: "success" };
	} else {
		return { status: "error" };
	}
};
export default login;
