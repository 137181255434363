import { useState, useEffect } from 'react';
import fetch from 'unfetch';

const updatePartyToken = async (spot_token) => {
	const url = `/api/spotify/updateCurrParties`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({ spot_token: spot_token }),
	});
	if (res.ok) {
		return { status: 'success', message: 'none' };
	} else {
		return {
			status: 'error',
			message:
				'Please try again. Your current party tokens did NOT update. ',
		};
	}
};

export default updatePartyToken;
