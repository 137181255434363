import { useEffect, useState } from 'react';

import HostTag from '../../General/UI/Tags/HostTag';
import { IonImg } from '@ionic/react';
import { MdAdd } from 'react-icons/md';
import getManyUsers from '../../../data/Users/getManyUsers';

const HPHostsSlide = ({
	HostData,
	SetSearchUserModalStatus,
	SetUserModalActive,
	isOwner,
	RemoveHost,
	SetConfirmActive,
	ErrorMessage,
}) => {
	const [hosts, setHosts] = useState(null);

	useEffect(() => {
		getManyUsers(HostData, 'phoneNumber').then((hosts) => {
			setHosts(hosts);
		});
	}, [HostData]);

	return (
		<div className="fc-center-full-full overflow-y-scroll">
			<div className="grid grid-cols-2 gap-y-4 w-full mt-4 px-2 content-start justify-items-center">
				{hosts?.map((host, idx) => {
					return (
						<HostTag
							key={idx}
							Host={host}
							SetPopupState={SetUserModalActive}
							isOwner={isOwner}
							OwnerFunction={RemoveHost}
							SetConfirmActive={SetConfirmActive}
							ErrorMessage={ErrorMessage}
						/>
					);
				})}
			</div>
			{isOwner ? (
				<div
					onClick={() => {
						SetSearchUserModalStatus(true);
					}}
					className="w-1/2 rounded-full border border-otto-tertiary dark:border-otto-tertiary-dark mt-4 py-2">
					{' '}
					Add Host(s)
				</div>
			) : null}
		</div>
	);
};

export default HPHostsSlide;
