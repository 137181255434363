import { AiOutlineClockCircle, AiOutlineClose } from 'react-icons/ai';
import { FaSpotify } from 'react-icons/fa';
import MdLoadingButton from '../../UI/MdLoadingButton';
import { MdPinDrop } from 'react-icons/md';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import requestQueue from '../../../../data/Spotify/requestQueue';
const SpotifySongModal = ({ data, SetState }) => {
	console.log(data);
	const location = useLocation();
	const partyId = location.pathname.split('/')[2];
	return (
		<div className="relative fc-center-full-full">
			{data?.album?.images[0] ? (
				<div className="w-full h-3/5 rounded-t-lg relative">
					<div className=""></div>
					<img
						src={data?.album.images[0].url}
						alt="song_image"
						className="w-full h-3/4 mt-8 object-cover"
					/>
				</div>
			) : (
				<div className="w-full h-1/3  rounded-t-lg from-otto-primary dark:from-otto-primary-dark" />
			)}
			<div className="text-xl -mt-4 z-20 text-center text-gray-900 font-semibold fr-center-evenly-full pt-.5">
				{data?.name || 'No Song Name'}
			</div>
			<div className="fr-center-evenly-full w-3/4 pt-1 text-gray-900">
				<div className="fr-center text-xs">
					{data?.artists[0]?.name || 'No Song Artist'}
				</div>
			</div>
			<div className="fr-center text-xs pt-0.5 z-50 text-gray-900">
				{data?.album?.name || 'No Song Album'}
			</div>
			<div className="text-xs w-2/3 mt-4 h-34 overflow-y-scroll fc-center-full pt-2.5">
				<MdLoadingButton
					Message="Queue Song"
					AdditionalTW="bg-otto-tertiary dark:bg-otto-tertiary-dark hover:border-otto-primary dark:hover:border-otto-primary-dark mb-4 py-2 hover:bg-otto-primary dark:hover:bg-otto-primary-dark px-1"
					ClickFunction={() => {
						requestQueue(
							partyId,
							data?.name,
							data?.album?.images[0].url,
							data?.uri,
							data?.artists[0]?.name
						).then((data) => {
							console.log(data);
						});
						SetState(false);
					}}
				/>
			</div>
			<div className="mx-auto -mt-18 text-center flex flex-col border-white shadow-2xl border-2 rounded-lg py-2 px-2 border-opacity-10">
				<FaSpotify className="text-4xl mx-auto" />
				<div className="text-center text-sm">
					Search and songs sponsored by{' '}
					<a href="spotify.com">Spotify</a>
				</div>
			</div>
		</div>
	);
};

export default SpotifySongModal;
