import { useEffect, useState } from 'react';

import { FaSpotify } from 'react-icons/fa';
import generateAuthToken from '../../../../data/Spotify/generateAuthToken';
import getPlaylists from '../../../../data/Spotify/getPlaylistFunc';
import getProfileData from '../../../../data/Spotify/getProfile';
import hasRefreshToken from '../../../../data/Spotify/hasRefreshToken';
import spotify from '../../../../data/Spotify/spotify';
import { useHistory } from 'react-router';
import verifyUser from '../../../../data/UserAuth/verifyUser';
import updatePartyToken from '../../../../data/Spotify/updateParties';
import signoutSpotify from '../../../../data/Spotify/signout';
import { CgSpinnerTwo } from 'react-icons/cg';
const SpotifyConfigurationModalBody = ({ match }) => {
	const [spotifyCode, setCode] = useState(null);
	verifyUser();
	//let url ='https%3A%2F%2Fbeta.otto-party.com%2Fsettings&scope=user-read-private%20user-read-email%20user-modify-playback-state%20user-read-currently-playing%20user-read-playback-position%20playlist-read-private&state=34fFs29kd09';
	let url =
		'https%3A%2F%2Fbeta.otto-party.com%2Fsettings&scope=user-read-private%20user-modify-playback-state%20user-read-currently-playing%20user-read-playback-position%20playlist-read-private&state=34fFs29kd09';
	console.log('THIS IS THE FILE CHANGING 1');
	const queryParams = new URLSearchParams(window.location.search);
	const code = queryParams.get('code');
	const [spotifyData, setSpotifyData] = useState(null);
	const [isLoggedIn, setIsLoggedIn] = useState(null);
	const [isPlaylistMenuActive, setPlaylistMenuStatus] = useState(false);
	const [currAccessToken, setCurrAccessToken] = useState(null);
	const [playlists, setPlaylists] = useState(null);
	useEffect(() => {
		console.log('Refreshing integration');
		hasRefreshToken().then((data) => {
			if (data) {
				setIsLoggedIn(true);
			} else {
				setIsLoggedIn(false);
			}
		});
	}, [code, match]);
	useEffect(() => {
		console.log('CODE IS', spotifyCode);
		if (code) {
			setCode(code);
			console.log('CODE IS', spotifyCode);

			spotify(code, url).then((data) => {
				// window.location = `/settings?code=${code}`;
				updatePartyToken(code); // This code is to prevent idiots like myself. Basically if u try to refresh your code this will overwrite your parties current token so they work.

				setIsLoggedIn(true);
				setSpotifyData(JSON.parse(data));
				console.log(JSON.parse(data));
			});
		} else if (!localStorage.getItem('spotify_access_token')) {
			generateAuthToken().then((data) => {
				getProfileData(
					localStorage.getItem('spotify_access_token'),
					url
				).then((data) => {
					setSpotifyData(JSON.parse(data));
				});
			});
			console.log('SIGNING IN USER');
		} else {
			getProfileData(
				localStorage.getItem('spotify_access_token'),
				url
			).then((data) => {
				console.log('SData', data);
				setSpotifyData(JSON.parse(data));
			});
			getPlaylists(
				localStorage.getItem('spotify_access_token'),
				url
			).then((data) => {
				setPlaylists(JSON.parse(data));
			});
		}
	}, [isLoggedIn, code]);

	const history = useHistory();

	return !isLoggedIn ? (
		<div className="fc-center-around-full-full bg-white rounded-lg">
			{spotifyData ? (
				<>
					<div className="fc-center-full">
						<FaSpotify className="text-5xl text-green-400" />
						<div className="text-lg font-semibold">
							Configure Spotify
						</div>
						<div className=" text-gray-900 w-2/3 text-2xl font-bold text-center mt-2">
							Tap below to connect your spotify account
							<div className="text-sm font-style-italic font-light">
								We do not store any of your data and only ask to
								access: your playback position, your playlists
								(beta), and to modify your currently playing. At
								no point will we sell or share your data, it's
								only current use is to allow you to queue
								suggested songs.
							</div>
						</div>
					</div>
					<div
						className="md-button py-1 bg-green-600 animate-pulse"
						onClick={() => {
							console.log('REDIRECTING');
							window.location = `https://accounts.spotify.com/authorize?client_id=720919e1a914417081a2fecf7f9f86bc&response_type=code&redirect_uri=${url}`;
						}}>
						Connect
					</div>
				</>
			) : (
				<CgSpinnerTwo className="text-green-400 text-4xl mx-auto my-auto animate-spin" />
			)}
		</div>
	) : (
		<div className="fc-center-around-full-full bg-white rounded-lg">
			<div className="fc-center-full">
				<FaSpotify className="text-5xl text-green-400" />
				<div className="text-lg font-semibold">Configure Spotify</div>
				<div className="subtext w-2/3 text-center mt-2">
					You are already signed in!
				</div>
			</div>
			<button
				className="font-bold rounded-lg px-8 py-2 bg-green-400"
				onClick={async () => {
					await signoutSpotify();
					setIsLoggedIn(false);
				}}>
				Signout
			</button>
		</div>
	);
};

export default SpotifyConfigurationModalBody;
