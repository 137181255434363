import fetch from 'unfetch';
import useSWR from 'swr';

const GetPartyGuestsSWR = async (partyId) => {
	const fetcher = (...args) =>
		fetch(...args, {
			headers: {
				authorization: localStorage.getItem('auth'),
				'content-type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify({ partyId: partyId }),
		}).then((res) =>
			res.json().then((data) => {
				return data;
			})
		);

	const { data: guests, error } = useSWR(
		`/api/party/fetchGuestInfo`,
		fetcher,
		{
			refreshInterval: 3000,
		}
	);

	console.log('Guest data is', guests);
	return guests;
};

export default GetPartyGuestsSWR;
