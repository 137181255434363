import fetch from 'unfetch';

const fetchPartyInvites = async (userInfo) => {
	const url = `/api/party/Invites/find.js`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({ userInfo: userInfo }),
	});

	if (res.ok) {
		return { data: await res.json() };
	} else {
		return { status: 'error' };
	}
};

export default fetchPartyInvites;
