import fetch from 'unfetch';

const updatePartySettings = async (partyId, settingId, newValue) => {
	const url = `/api/party/settings/update`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({ partyId: partyId, settingId:settingId, newValue:newValue }),
	});

	if (res.ok) {
		return { status: 'succ', data: await res.json() };
	} else {
		return { status: 'error' };
	}
};
export default updatePartySettings;
