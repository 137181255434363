import { useState, useEffect } from 'react';
import fetch from 'unfetch';
/**
 *
 * @param {*} code The code from the user to check
 * @param {*} phoneNumber The phone number to check against the database
 * @param {*} password The users password. !IMPORTANT! This will be the password in the db
 * @returns status:success or status:error. This also !!IMPORTANT!! creates a temporary auth with the users
 *  phone number in a jwt. This allows us to securely verify the users ph in the database. Prevents circumvention of the auth.
 */
const verifyph = async (code, phoneNumber, password) => {
	console.log(code);
	const url = `/api/signup/verifysms`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
		},
		body: JSON.stringify({
			code: code,
			phoneNumber: phoneNumber,
			password: password,
		}),
	});

	if (res.ok) {
		let b = await res.json();
		console.log('RES IS', b);
		if (b.code > 0) {
			return { status: 'error' };
		} else {
			localStorage.setItem('auth', b.jwt);

			return { status: 'success' };
		}
	} else {
		return { status: 'error' };
	}
};
export default verifyph;
