import fetch from 'unfetch';

const getProfileData = async (access_token) => {
	console.log('getting called', access_token);
	const url = `/api/spotify/getProfileData`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			access_token: localStorage.getItem('spotify_access_token'),
		}),
	});

	if (res.ok) {
		let h = await res.json();
		return await h;
	} else {
		return false;
	}
};
export default getProfileData;
