import { IonContent, IonPage, IonToast } from '@ionic/react';
import { useEffect, useState } from 'react';

import BackArrow from '../../components/General/Navigation/BackArrow';
import BackgroundHeader from '../../components/General/UI/BackgroundHeader';
import MdLoadingButton from '../../components/General/UI/MdLoadingButton';
import MdTelInput from '../../components/General/UI/Forms/Md/MdTelInput';
import MdTextInput from '../../components/General/UI/Forms/Md/MdTextInput';
import SMSAuthModal from '../../components/General/Modals/SMSAuthModal';
import { VerifyPhoneNumber } from '../../components/utils/FormInputVerification';
import joinPartySMS from '../../data/party/join/joinPartySMS';
import { useHistory } from 'react-router';
import verifyPartySMS from '../../data/party/join/verifyPartySMS';
import RSVPInfo from '../../data/party/RSVPInfo';

const JoinPartyPage = ({ match }) => {
	const [isLoading, setLoadingStatus] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState(null);
	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [error, setError] = useState(null);
	const [isSMSAuthPopActive, setSMSAuthPopStatus] = useState(false);
	const [pin, setPin] = useState(null);
	const [isModalLoading, setModalLoadingStatus] = useState(false);
	const [partyData, setPartyData] = useState(false);
	console.log(match);
	const router = useHistory();

	const HandleJoinSubmit = () => {
		let phoneRes = VerifyPhoneNumber(phoneNumber);
		if (phoneRes !== true) {
			setError(phoneRes);
			return false;
		}

		setLoadingStatus(true);
		setModalLoadingStatus(false);
		console.log(lastName);
		joinPartySMS(phoneNumber).then((resp) => {
			if (resp !== 'error') {
				setSMSAuthPopStatus(true);
			} else {
				setError('ERROR: Could not process phone number');
			}
		});
	};
	useEffect(() => {
		RSVPInfo(match?.params?.pid).then((data) => {
			if (data?.data?.data) {
				let d = Date.now();
				let d2 = new Date(data?.data?.data?.start_time);
				if (d2 >= d) {
					router.push('/RSVP/' + match?.params?.pid);
				}
			}
		});
	}, []);
	const SMSClickFunction = () => {
		setModalLoadingStatus(true);
		verifyPartySMS(
			pin,
			phoneNumber,
			firstName,
			lastName,
			match.params.pid
		).then((resp) => {
			if (resp.status === 'success') {
				console.log('cool');
				router.push('/party/' + match.params.pid);
			} else {
				setModalLoadingStatus(false);
				setError('Invalid Code');
			}
		});
	};
	useEffect(() => {}, []);
	useEffect(() => {
		if (isSMSAuthPopActive === false) {
			setLoadingStatus(false);
		}
	}, [isSMSAuthPopActive]);

	return (
		<IonPage>
			<BackgroundHeader />
			<IonContent scrollY={false} color="primary" fullscreen>
				<div className="fc-center-full-full justify-evenly relative">
					<BackArrow />
					<div className="text-7xl line-through">OTTO</div>
					<div className="fc-center w-2/3">
						<MdTelInput SetState={setPhoneNumber} />
						<MdTextInput
							AdditionalTW="mt-4"
							SetState={setFirstName}
							Placeholder={'First Name'}
						/>
						<MdTextInput
							AdditionalTW="mt-4"
							SetState={setLastName}
							Placeholder={'Last Name'}
						/>
					</div>
					<MdLoadingButton
						className="mt-4"
						ClickFunction={HandleJoinSubmit}
						isLoading={isLoading}
						Message="Join"
					/>
				</div>
			</IonContent>
			{isSMSAuthPopActive ? (
				<SMSAuthModal
					SetState={setSMSAuthPopStatus}
					SMSClickFunction={SMSClickFunction}
					RenewSMS={HandleJoinSubmit}
					SetPin={setPin}
					IsModalLoading={isModalLoading}
				/>
			) : null}
			<IonToast
				position="top"
				isOpen={error !== null}
				message={`ERROR MESSAGE: ${error}`}
				color="secondary"
				duration={2000}
				onDidDismiss={() => {
					setError(null);
				}}></IonToast>
		</IonPage>
	);
};

export default JoinPartyPage;
