import { useEffect, useState } from 'react';

import { AiOutlineClose } from 'react-icons/ai';
import { IonImg } from '@ionic/react';

const HostTag = ({
	Host,
	SetPopupState,
	isOwner,
	OwnerFunction,
	SetConfirmActive,
	ErrorMessage,
}) => {
	const ConfirmCancel = () => {
		// console.log('Cancelled', data?.UUID);
		ErrorMessage('Host Not Removed');
	};
	return (
		<div
			onClick={() => {
				SetPopupState(Host, false);
			}}
			className="fc-center-md bg-gray-200 dark:bg-gray-700 rounded-xl p-4 w-5/6 shadow-lg relative z-10">
			{isOwner ? (
				<AiOutlineClose
					onClick={(e) => {
						e.stopPropagation();
						// OwnerFunction(Host);
						SetConfirmActive(OwnerFunction, ConfirmCancel, Host);
					}}
					className="absolute top-1.5 right-1.5 text-2xl bg-gray-400 dark:bg-gray-500 rounded-full p-1 z-20"
				/>
			) : null}
			{Host?.profile_image ? (
				<div className="w-20 h-20 ml-1 rounded-full overflow-hidden flex-shrink-0">
					<IonImg
						src={Host.profile_image}
						className="w-full h-full object-cover"
					/>
				</div>
			) : (
				<div className="h-20 w-20 ml-1 bg-otto-tertiary dark:bg-otto-tertiary-dark rounded-full flex-shrink-0 font-semibold fr-center-center-full text-2xl">
					{Host?.firstName?.slice(0, 1)}
					{Host?.lastName?.slice(0, 1)}
				</div>
			)}
			<div className="whitespace-nowrap mt-2">
				{Host?.firstName} {Host?.lastName}
			</div>
			<div className="subtext">@{Host?.username}</div>
		</div>
	);
};

export default HostTag;
