import { useEffect, useState } from 'react';

import fetch from 'unfetch';

/**
 *
 * @param {*} id_array array of ids
 * @param {*} id_type can be "phoneNumber" for phone numbers, UUID for UUID, or username for usernames !IMPORTANT! They are case sensitive
 * @returns profile images for them and some basic data
 */
const getGroupOfUsers = async (phoneNumbers, id_type) => {
	const url = `/api/getManyUsers`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({ names: phoneNumbers, id_type: id_type }),
	});

	if (res.ok) {
		let d = await res.json();
		return d;
	} else {
		return null;
	}
};
export default getGroupOfUsers;
