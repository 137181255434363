import { IonContent, IonPage } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';

import Footer from '../../components/General/Navigation/Footer';
import Header from '../../components/General/Navigation/Header';
import ModalOutline from '../../components/General/Modals/ModalOutline';
import PartyModal from '../../components/General/Modals/PartyModal';
import PartyTag from '../../components/General/UI/Tags/PartyTag';
import fetchPartyInvites from '../../data/party/invites/fetchPartyInvites';
const HomePage = () => {
	const [userData] = useState(
		JSON.parse(localStorage.getItem('userData'))?.data
	);
	const [isPartyModalActive, setPartyModalStatus] = useState(false);
	const [partyData, setPartyData] = useState(null);
	const [partyIndex, setPartyIndex] = useState(null);



		useEffect(() => {
			fetchPartyInvites(userData?.data?.phoneNumber).then((data)=>{
				console.log(data)
				if(data?.data?.newArray){
					setPartyData(data?.data?.newArray)
				}
			})
		}, []);


	const SetPartyModalStatus = (index) => {
		setPartyIndex(index);
		setPartyModalStatus(true);
	};

	return (
		<IonPage>
			<Header />
			<IonContent color="primary" scrollY={false} fullscreen>
				<div className="fc-center-full-full py-2">
					<div className="fr-center-between-full px-2">
						<div className="text-xl font-semibold">
							Welcome back{' '}
							<span className="text-otto-tertiary dark:text-otto-tertiary-dark font-bold text-2xl">
								{userData?.firstName}
							</span>
						</div>
					</div>
					<div className="fc-center-full overflow-y-scroll">
						<div className="mt-4 text-center w-full mb-2 border-b border-otto-tertiary dark:border-otto-tertiary-dark px-2 bg-otto-tertiary dark:bg-otto-tertiary-dark rounded-t-xl pt-1 pb-0.5 font-semibold">
							Upcoming Events
						</div>
						<div className="fc-center-full space-y-2 px-2 overflow-y-scroll h-full overflow-x-hidden">
							{partyData ? (
								partyData.map((item, idx) => {
									let index = idx;
									return (
										<PartyTag
											partyData={item}
											key={idx}
											Index={index}
											SetStatus={SetPartyModalStatus}
										/>
									);
								})
							) : (
								<div className="subtext">
									No Upcoming Parties
								</div>
							)}
						</div>
					</div>
				</div>
				{isPartyModalActive ? (
					// <ModalOutline
					// 	SetState={setPartyModalStatus}
					// 	Modal={React.cloneElement(PartyModal, {
					// 		partyData: partyIndex[partyIndex],
					// 	})}
					// />
					<ModalOutline
						ModalType={PartyModal}
						ModalData={partyData[partyIndex]}
						SetState={setPartyModalStatus}
					/>
				) : null}
			</IonContent>
			<Footer />
		</IonPage>
	);
};

export default HomePage;
