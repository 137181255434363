import { useEffect, useRef, useState } from 'react';

import { IonImg } from '@ionic/react';
import { MdAddAPhoto } from 'react-icons/md';

const AddPartyPhoto = ({ SetState }) => {
	const [preview, setPreview] = useState(null);
	const fileInputRef = useRef(null);
	const [image, setImage] = useState(null);

	useEffect(() => {
		if (image) {
			console.log(image);
			let h;
			const reader = new FileReader();
			reader.onloadend = () => {
				h = reader.result;
				setPreview(reader.result);
				SetState(h);
			};

			reader.readAsDataURL(image);
		} else {
			setPreview(null);
			SetState(null);
		}
	}, [image]);

	return (
		<div className="">
			{preview ? (
				<div className="w-32 h-32 overflow-hidden rounded-full border border-solid border-otto-secondary dark:border-otto-secondary-dark ">
					<IonImg
						src={preview}
						alt="ProfilePhotoPreview"
						className="transition ease-in-out duration-300s cursor-pointer hover:opacity-80 h-32 w-32 rounded-full object-cover"
						onClick={(event) => {
							console.log('here');
							event.preventDefault();
							fileInputRef.current.click();
						}}></IonImg>
				</div>
			) : (
				<button
					className="focus:outline-none flex flex-col items-center h-32 w-32 rounded-full border border-solid border-otto-secondary dark:border-otto-secondary-dark cursor-pointer transition ease-in-out duration-300s justify-center"
					onClick={(event) => {
						event.preventDefault();
						fileInputRef.current.click();
					}}>
					<MdAddAPhoto className="text-4xl" />
				</button>
			)}

			<input
				ref={fileInputRef}
				type="file"
				className="hidden"
				accept="image/*"
				onChange={(event) => {
					const file = event.target.files[0];
					if (file && file.type.substring(0, 5) === 'image') {
						setImage(file);
					} else {
						setImage(null);
					}
				}}></input>
		</div>
	);
};

export default AddPartyPhoto;
