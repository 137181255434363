import { useRef, useState } from 'react';

import { IonTextarea } from '@ionic/react';

const MdTextArea = ({
	AdditionalTW,
	SetState,
	Placeholder,
	isMd,
	MaxLength,
}) => {
	const [isInputFocused, setInputFocusStatus] = useState(false);
	const textarea = useRef();

	const HandleChange = () => {
		SetState(textarea.current.value);
	};

	return (
		<IonTextarea
			ref={textarea}
			maxlength={MaxLength || 250}
			inputMode="text"
			placeholder={Placeholder || 'PH'}
			className={`${AdditionalTW} + 
        ${
			isInputFocused
				? isMd
					? 'input-textarea-focused-md'
					: 'input-textarea-focused-full'
				: isMd
				? 'input-textarea-md'
				: 'input-textarea-full'
		}
    `}
			onIonFocus={() => {
				setInputFocusStatus(true);
			}}
			onIonBlur={() => {
				setInputFocusStatus(false);
			}}
			onIonChange={HandleChange}
		/>
	);
};

export default MdTextArea;
