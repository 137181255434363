import {
	IonContent,
	IonHeader,
	IonSearchbar,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react';

import SpotifySongTag from '../../UI/Tags/SpotifySongTag';
import searchSong from '../../../../data/Spotify/searchSong';
import { useLocation } from 'react-router';
import { useState } from 'react';

const SpotifySearchModalBody = ({ SetState, SetSecondState }) => {
	const [spotifySearchResults, setSpotifySearchResults] = useState(null);

	const [errorMessage, setErrorMessage] = useState(null);
	const location = useLocation();

	const SpotifySearch = (val) => {
		const partyId = location.pathname.split('/')[2];
		// TODO: Not happy with how I get partyId, works but not good
		searchSong(val, partyId).then((resp) => {
			if (resp === false) {
				setErrorMessage(
					'Error loading spotify results. Try configuring in settings'
				);
			} else {
				setSpotifySearchResults(JSON.parse(resp)?.tracks?.items);
			}
		});
	};

	return (
		<div className="fc-center-full-full">
			<IonHeader translucent color="secondary">
				<IonToolbar color="secondary">
					<IonTitle>Spotify Search</IonTitle>
				</IonToolbar>
				<IonToolbar color="secondary">
					<IonSearchbar
						onIonChange={(e) => {
							SpotifySearch(e.target.value);
						}}
					/>
				</IonToolbar>
			</IonHeader>
			<IonContent color="secondary">
				<div className="fc-center-full">
					{spotifySearchResults
						? spotifySearchResults.map((track, idx) => {
								return (
									<SpotifySongTag
										key={idx}
										songData={track}
										SetState={SetState}
										SetSecondState={SetSecondState}
									/>
								);
						  })
						: null}
				</div>
			</IonContent>
			<IonToast
				position="top"
				isOpen={errorMessage !== null}
				message={`ERROR MESSAGE: ${errorMessage}`}
				color="secondary"
				duration={2000}
				onDidDismiss={() => {
					setErrorMessage(null);
				}}></IonToast>
		</div>
	);
};

export default SpotifySearchModalBody;
