import {
	GetDayIntFromDate,
	GetDayStringFromDate,
	GetFullTDStringFromDate,
	GetMonthIntFromDate,
	GetMonthStringFromDate,
	GetTimeStringFromTimeStamp,
	GetYearFromData,
} from '../../utils/FormatData';
import { MdDateRange, MdLocationOn } from 'react-icons/md';

import { AiOutlineClockCircle } from 'react-icons/ai';
import { BsClockFill } from 'react-icons/bs';
import GuestMap from './GuestMap';
import { MdPinDrop } from 'react-icons/md';
import { Route } from 'workbox-routing';

const GPAboutSlide = ({ partyData, Slides }) => {
	return (
		<div className="fc-center-full-full">
			{/* <div
				className="h-1/3 w-full bg-red-500 overflow-hidden"
				onTouchStart={() => {
					Slides.current.lockSwipes(true);
				}}
				onTouchEnd={() => {
					Slides.current.lockSwipes(false);
				}}>
				<GuestMap
					longitude={partyData?.location?.longitude}
					latitude={partyData?.location?.latitude}
				/>
			</div> */}
			<div className="w-full text-right">
				<a
					target="_blank"
					rel="noopener noreferrer"
					href="https://www.google.com/maps?q=6234+Pershing+Avenue+Saint+Louis+MO+63130"
					className="subtext font-semibold w-full text-right p-2 text-otto-secondary dark:text-otto-secondary-dark">
					Directions
				</a>
			</div>
			<div className="w-full rounded-sm shadow-full px-2">
				<div className="font-semibold w-full text-left text-otto-tertiary dark:text-otto-tertiary-dark">
					Party Details
				</div>
				<div className="w-full pt-1">
					<div className="fc-full fr-full text-left pl-2 py-1">
						<MdLocationOn></MdLocationOn>
						<div className="text-sm font-light pl-2">
							{partyData?.location}
						</div>
					</div>
					<div className="fc-full fr-full text-left pl-2 py-1">
						<MdDateRange> </MdDateRange>
						<div className="text-sm font-light pl-2">
							{GetMonthStringFromDate(partyData?.start_time)},{' '}
							{GetDayIntFromDate(partyData?.start_time)}{' '}
							{GetYearFromData(partyData?.start_time)}
						</div>
					</div>
					<div className="fc-full fr-full text-left pl-2 py-1">
						<BsClockFill> </BsClockFill>
						<div className="text-sm font-light pl-2">
							{GetTimeStringFromTimeStamp(partyData?.start_time)}{' '}
							- {GetTimeStringFromTimeStamp(partyData?.end_time)}
						</div>
					</div>
				</div>
			</div>
			<div className="w-full px-2 pt-1.5">
				<div className="font-semibold w-full text-left text-otto-tertiary dark:text-otto-tertiary-dark">
					Party Description
				</div>
				<div className="text-xs w-full text-left pt-2 pl-2">
					{partyData?.description}
				</div>
			</div>
		</div>
	);
};

export default GPAboutSlide;
