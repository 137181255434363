import fetch from 'unfetch';
/**
 *
 * @param {*} type incident type. 0 === emergency. This will override any custom message
 * @param {*} message User Message
 * @param {*} partyId  Id of the party. Verified on backend.
 * @returns
 */
const submitIncident = async (type, message, partyId) => {
	const url = `/api/incidents/emergency`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			type: type,
			message: message,
			partyId: partyId,
		}),
	});

	if (res.ok) {
		return { status: 'succ', data: await res.json() };
	} else {
		return { status: 'error' };
	}
};
export default submitIncident;
