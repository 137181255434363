import { IonContent, IonPage } from '@ionic/react';

import BackgroundHeader from '../../components/General/UI/BackgroundHeader';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import verifyUser from '../../data/UserAuth/verifyUser';

const LandingPage = () => {
	const router = useHistory();
	useEffect(() => {
		verifyUser().then((data) => {
			console.log('Verified Data', data);
			if (data.status !== 'error') {
				router.push('/home');
			}
		});
	}, []);
	return (
		<IonPage>
			<BackgroundHeader />
			<IonContent scrollY={false} color="primary" fullscreen>
				<div className="fc-center-full-full justify-evenly">
					<div className="text-7xl line-through">OTTO</div>
					<div className="fc-center w-2/3 -mt-18">
						<div
							className="w-4/5 text-center p-4 rounded-lg border shadow-2xl border-opacity-10 my-12"
							onClick={() => {
								router.push('/login');
							}}>
							Login
						</div>
						<div
							className="w-4/5 text-center p-4 rounded-lg border shadow-2xl border-opacity-10"
							onClick={() => {
								router.push('/signup');
							}}>
							Sign Up
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default LandingPage;
