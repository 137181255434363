import { useEffect, useState } from 'react';

import HostTag from '../../General/UI/Tags/HostTag';
import getManyUsers from '../../../data/Users/getManyUsers';

const GPHostsSlide = ({ HostData, SetPopupState }) => {
	const [hosts, setHosts] = useState(null);

	useEffect(() => {
		getManyUsers(HostData, 'phoneNumber').then((hosts) => {
			setHosts(hosts);
		});
	}, [HostData]);

	return (
		<div className="grid grid-cols-2 gap-y-4 w-full h-full mt-4 overflow-y-scroll px-2 content-start justify-items-center">
			{hosts?.map((host, idx) => {
				return (
					<HostTag
						key={idx}
						Host={host}
						SetPopupState={SetPopupState}
					/>
				);
			})}
		</div>
	);
};

export default GPHostsSlide;
