import fetch from 'unfetch';
/**
 *
 * @param {*} partyId The unique party identifier
 * @param {*} startTime  The start time of the party
 * @param {*} endTime The end time of the party
 * @param {*} description Descriptionc
 * @param {*} file Base64 encoded image
 * @returns Status: "success" or Status:"error"
 */

const createParty = async (
	partyId,
	startTime,
	endTime,
	description,
	file,
	location
) => {
	const url = `/api/createParty/submit`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: localStorage.getItem('auth'),
		},
		body: JSON.stringify({
			partyId: partyId,
			startTime: startTime,
			endTime: endTime,
			description: description,
			file: file,
			location: location,
		}),
	});

	if (res.ok) {
		let newPartId = partyId?.replace(/\s/g, '');
		window.location = `/party/${newPartId}/host`;
		return { status: 'success' };
	} else {
		return { status: 'error' };
	}
};
export default createParty;
