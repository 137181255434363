import MdLoadingButton from '../UI/MdLoadingButton';
import MdTextArea from '../UI/Forms/Md/MdTextArea';
import MdTextInput from '../UI/Forms/Md/MdTextInput';
import submitIncident from '../../../data/party/incidents/submitIncident';
import { useLocation } from 'react-router';
import { useState } from 'react';

const ReportProblemModalBody = ({
	SetState,
	SetSecondState,
	SetThirdState,
}) => {
	const [issue, setIssue] = useState(null);
	const [description, setDescription] = useState(null);
	const [isLoading, setLoadingStatus] = useState(false);
	const location = useLocation();

	const HandleCustomSubmit = async () => {
		setLoadingStatus(true);
		if (!issue) {
			SetSecondState('Please enter a valid issue');
			setLoadingStatus(false);
			return false;
		}

		if (!description) {
			SetSecondState('Please enter a valid description');
			setLoadingStatus(false);
			return true;
		} else {
			const partyId = location.pathname.split('/')[2];
			submitIncident(issue, description, partyId).then((res) => {
				console.log("Here")
				console.log(res);
				if (res?.status !== 'error') {
					SetState(false);
					SetSecondState(
						`Issue of "${issue}" successfully submitted`
					);
					setLoadingStatus(false);
				} else {
					SetState(false);
					SetSecondState(
						`Issue of "${issue}" could not be submitted`
					);
					setLoadingStatus(false);
				}
			});
		}
	};

	const HandleSOS = async () => {
		const partyId = location.pathname.split('/')[2];
		await submitIncident(0, 'None', partyId);
		console.log('SOS Submit');
		SetSecondState('SOS Confirmed');
	};

	const CancelledSOS = () => {
		SetSecondState('SOS Cancelled');
		console.log('cancelled');
	};

	return (
		<div className="fc-center-full-full justify-evenly py-4 bg-white rounded-lg text-black">
			<div className="flex-1 mb-auto fc-center-full">
				<MdTextInput
					SetState={setIssue}
					Placeholder="Issue"
					isMd={true}
					AdditionalTW=""
				/>
				<MdTextArea
					SetState={setDescription}
					Placeholder="Description"
					isMd={true}
					AdditionalTW="mt-4"
				/>
				<MdLoadingButton
					ClickFunction={HandleCustomSubmit}
					isLoading={isLoading}
					Message="Submit"
					AdditionalTW="py-2 mt-4 w-1/2"
				/>
			</div>
			<div className="fr-center-evenly-full flex-1 ">
				<div className="h-px w-1/3 bg-gray-500"></div>
				<div className="text-gray-500">OR</div>
				<div className="h-px w-1/3 bg-gray-500"></div>
			</div>
			<div className="fc-center-full flex-1 mt-auto">
				<div
					onClick={() => SetThirdState(HandleSOS, CancelledSOS)}
					className="bg-otto-danger p-2 rounded-full h-28 w-28 fr-center-center-full text-white font-bold text-xl">
					SOS
				</div>
				<div className="text-sm text-center mt-4">
					*Button will send emergency text to all hosts
				</div>
			</div>
		</div>
	);
};

export default ReportProblemModalBody;
