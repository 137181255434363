import { MdDateRange, MdLocationOn } from 'react-icons/md';

import { AiOutlineClockCircle } from 'react-icons/ai';
import { BsClockFill } from 'react-icons/bs';
import { MdPinDrop } from 'react-icons/md';
import { Route } from 'workbox-routing';
import { useEffect, useState, useRef } from 'react';
import RSVP from '../../data/party/RSVP';
const RSVPPopUp = ({ partyId, setState, setDidRSVP }) => {
	let firstName = useRef(null);
	let lastName = useRef(null);
	const [yes, setYes] = useState(false);
	const [maybe, setMaybe] = useState(false);
	const [no, setNo] = useState(false);
	const inputStyle =
		'w-2/3 bg-opacity-0 mt-4 h-12 shadow-sm mx-auto bg-gray-900 outline-none border-b border-black';
	return (
		<div className="w-full h-full flex z-50">
			<div className=" w-5/6 h-2/3 bg-gray-800 my-auto mx-auto my-auto z-50 rounded-lg flex flex-col border border-white shadow-2xl ">
				<div
					onClick={() => {
						setState(false);
					}}
					className="relative left-6 top-6 font-bold">
					X
				</div>
				<div className="mx-auto text-4xl font-bold mt-2 mb-6">
					RSVP.
				</div>
				<input
					ref={firstName}
					className={inputStyle}
					type="text"
					placeholder="First Name"></input>
				<input
					ref={lastName}
					className={inputStyle}
					type="text"
					placeholder="Last Name"></input>
				<div className="text-center text-xs mt-4">I will be there:</div>
				<div className="w-full mx-auto flex mt-4 text-center text-2xl">
					<button
						onClick={() => {
							setYes(true);
							setMaybe(false);
							setNo(false);
						}}
						className={
							yes
								? 'bg-green-500 py-2 px-4 rounded-lg mx-2 mx-auto shadow-2xl bg-opacity-100'
								: 'bg-green-700 py-2 px-4 rounded-lg mx-2 mx-auto bg-opacity-30'
						}>
						Yes
					</button>
					<button
						onClick={() => {
							setYes(false);
							setMaybe(true);
							setNo(false);
						}}
						className={
							maybe
								? 'bg-blue-500 py-2 px-4 rounded-lg mx-2 mx-auto shadow-2xl'
								: 'bg-blue-200 py-2 px-4 rounded-lg mx-2 mx-auto bg-opacity-30'
						}>
						Maybe
					</button>
					<button
						onClick={() => {
							setYes(no);
							setMaybe(false);
							setNo(true);
						}}
						className={
							no
								? 'bg-red-700 py-2 px-4 rounded-lg mx-2 mx-auto shadow-2xl'
								: 'bg-red-300 py-2 px-4 rounded-lg mx-2 mx-auto bg-opacity-30'
						}>
						No
					</button>
				</div>
				<button
					onClick={() => {
						if (yes || maybe) {
							let user = {
								firstName: firstName.current.value,
								lastName: lastName.current.value,
							};
							RSVP(partyId, user).then((data) => {
								console.log(data);
								setState(false);
								setDidRSVP(true);
							});
						} else {
							setState(false);
						}
					}}
					className="px-4 py-4 text-2xl bg-green-400 text-white rounded-lg w-2/3 mt-12 mx-auto">
					RSVP
				</button>
			</div>
		</div>
	);
};

export default RSVPPopUp;
