import { BiArrowBack } from 'react-icons/bi';
import { useHistory } from 'react-router';

const BackArrow = ({ RouteLocation, AdditionalTW }) => {
	const router = useHistory();

	const HandleClick = () => {
		RouteLocation !== undefined
			? router.push(RouteLocation)
			: router.goBack();
	};

	return (
		<BiArrowBack
			className={`absolute top-3 left-3 text-3xl ${AdditionalTW}`}
			onClick={HandleClick}
		/>
	);
};

export default BackArrow;
