import { useEffect } from 'react';
import { BiCheck, BiX } from 'react-icons/bi';
import { useState } from 'react';

const ConfirmationModal = ({
	SetState,
	DynamicMessage,
	SetSecondState,
	DynamicFunction1,
	DynamicFunction2,
	SetThirdState,
	data,
}) => {
	useEffect(() => {
		console.log('Dyn 1 CM', DynamicFunction1);
		console.log('Dyn 2 CM', DynamicFunction2);
		console.log(data);
	}, []);

	return (
		<div className="relative fc-center-full-full pt-5.5 bg-otto-secondary dark:bg-otto-tertiary-dark rounded-lg">
			<div className="text-2xl font-semibold">Are you sure?</div>
			<div className="text-sm text-center w-3/4 pt-2">
				{DynamicMessage}
			</div>
			{SetThirdState ? (
				<div
					className="full-button bg-red-500"
					onClick={() => {
						DynamicFunction1(data);
						SetState(false);
					}}>
					Confirm
				</div>
			) : (
				<div className="relative fc-center-full-full justify-center text-center">
					<div className="fr-center-evenly-full">
						<div
							className="h-32 w-32 bg-red-500 text-lg font-semibold"
							onClick={() => {
								DynamicFunction2(data);
								SetState(false);
							}}>
							Cancel
							<div className="fr-center-evenly-full pt-2.5 ">
								<BiX className="h-12 w-12"></BiX>
							</div>
						</div>

						<div
							className="h-32 w-32 bg-green-500 text-lg font-semibold"
							onClick={() => {
								DynamicFunction1(data);
								SetState(false);
							}}>
							Confirm
							<div className="fr-center-evenly-full  pt-2.5">
								<BiCheck className="h-12 w-12 "></BiCheck>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ConfirmationModal;
