import { useState, useEffect } from "react";
import fetch from "unfetch";
/**
 *
 * @param {*} phoneNumber The phone number to verify
 * @param {*}
 * @returns Sends an sms to the user. Returns either status:success or status:error.
 */
const joinPartySMS = async (phoneNumber) => {
	const url = `/api/party/join/smsauth`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"content-type": "application/json",
		},
		body: JSON.stringify({ phoneNumber: phoneNumber }),
	});

	if (res.ok) {
		return { status: "success" };
	} else {
		localStorage.clear();
		return { status: "error" };
	}
};
export default joinPartySMS;
