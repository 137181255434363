import {
	GetDayIntFromDate,
	GetDayStringFromDate,
	GetMonthStringFromDate,
	GetTimeStringFromTimeStamp,
} from '../../components/utils/FormatData';
import {
	IonContent,
	IonDatetime,
	IonItem,
	IonLabel,
	IonPage,
	IonRange,
	IonSlide,
	IonSlides,
	IonTextarea,
	IonToast,
} from '@ionic/react';
import {
	VerifyDescription,
	VerifyDuration,
	VerifyPartyImage,
	VerifyPartyName,
	VerifyStartDate,
} from '../../components/utils/FormInputVerification';
import { useEffect, useRef, useState } from 'react';

import AddPartyPhoto from '../../components/Party/CreateParty/AddPartyPhoto';
import BackArrow from '../../components/General/Navigation/BackArrow';
import { BsChevronRight } from 'react-icons/bs';
import { ImSpinner3 } from 'react-icons/im';
import LocationAutofill from '../../components/utils/LocationAutofill';
import MdTextArea from '../../components/General/UI/Forms/Md/MdTextArea';
import MdTextInput from '../../components/General/UI/Forms/Md/MdTextInput';
import createParty from '../../data/party/createParty';

const CreatePartyPage = () => {
	const [slideIndex, setSlideIndex] = useState(0);
	const [partyName, setPartyName] = useState(null);
	const [location, setLocation] = useState(null);
	const [description, setDescription] = useState(null);
	const [isSignUpLoading, setSignUpLoadingStatus] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [date, setDate] = useState(null);
	const [owner] = useState(
		JSON.parse(localStorage.getItem('userData')).data.UUID
	);
	const [duration, setDuration] = useState(null);
	const [partyPhoto, setPartyPhoto] = useState(null);

	const slideOptions = {
		initialSlide: 0,
		speed: 400,
	};

	const descRef = useRef(null);

	const slides = useRef(null);

	useEffect(() => {
		slides.current.lockSwipes(true);
	}, []);

	const NextSlide = async () => {
		await slides.current.lockSwipes(false);
		await slides.current.slideNext();
		await slides.current.lockSwipes(true);
		setSlideIndex(slideIndex + 1);
	};

	const PrevSlide = async () => {
		await slides.current.lockSwipes(false);
		await slides.current.slidePrev();
		await slides.current.lockSwipes(true);
		setSlideIndex(slideIndex - 1);
	};

	const HandleSignUpSubmit = () => {
		setSignUpLoadingStatus(true);
		const startDate = new Date(document.getElementById('start-time').value);
		createParty(
			partyName,
			date,
			new Date(startDate.getTime() + duration * 60000),
			description,
			partyPhoto,
			location
		).then((resp) => {
			if (resp.status === 'error') {
				setErrorMessage('Error Processing Party');
				setSignUpLoadingStatus(false);
			}
		});
	};

	const VerifyStageOne = () => {
		//TODO Isaiah Components/utils/FormInputVerification.js check on backend
		let partyNameRes = VerifyPartyName(partyName);
		if (partyNameRes !== true) {
			setErrorMessage(partyNameRes);
			return false;
		}
		let descriptionRes = VerifyDescription(description);
		if (descriptionRes !== true) {
			setErrorMessage(descriptionRes);
			return false;
		}
		NextSlide();
	};
	const VerifyStageTwo = () => {
		let startDateRes = VerifyStartDate(date);
		if (startDateRes !== true) {
			setErrorMessage(startDateRes);
			return false;
		}

		let durationRes = VerifyDuration(duration);
		if (durationRes !== true) {
			setErrorMessage(durationRes);
			return false;
		}

		NextSlide();
	};
	const VerifyStageThree = () => {
		let photoRes = VerifyPartyImage(partyPhoto);
		if (photoRes !== true) {
			setErrorMessage(photoRes);
			return false;
		}

		HandleSignUpSubmit();
	};

	const HandleTime = (value) => {
		const startDate = new Date(value);
		const endDate = new Date(startDate.getTime() + duration * 60000);
		return (
			<div className="fr-center-full justify-evenly border-t py-2 px-12 border-b border-otto-secondary dark:border-otto-secondary-dark">
				<div className="fc-center flex-1 mr-auto justify-center">
					<div className="whitespace-nowrap">
						{GetDayStringFromDate(startDate)},{' '}
						{GetMonthStringFromDate(startDate)}{' '}
						{GetDayIntFromDate(startDate)}
					</div>
					<div className="text-sm">
						{GetTimeStringFromTimeStamp(startDate)}
					</div>
				</div>
				<div>
					<BsChevronRight className="text-2xl flex-1 justify-center mx-2" />
				</div>
				<div className="fc-center flex-1 ml-auto justify-center">
					<div className="whitespace-nowrap">
						{GetDayStringFromDate(endDate)},{' '}
						{GetMonthStringFromDate(endDate)}{' '}
						{GetDayIntFromDate(endDate)}
					</div>
					<div className="text-sm">
						{GetTimeStringFromTimeStamp(endDate)}
					</div>
				</div>
			</div>
		);
	};

	return (
		<IonPage>
			<IonContent scrollY={false} fullscreen color="primary">
				<BackArrow />

				<div className=" fc-center-full-full justify-evenly">
					<div className="text-7xl line-through">OTTO</div>

					<IonSlides
						className="overflow-x-hidden w-full"
						ref={slides}
						options={slideOptions}>
						<IonSlide>
							<div className="fc-center text-left w-2/3 -mt-2">
								<MdTextInput
									SetState={setPartyName}
									Placeholder="Party Name"
								/>
								<LocationAutofill SetState={setLocation} />

								<div className="w-full relative">
									{/* TODO: See if works on IOS */}
									<MdTextArea
										SetState={setDescription}
										Placeholder="Description"
										AdditionalTW="mt-6"
										MaxLength={150}
									/>
									<div className="absolute -bottom-5 right-1 subtext">
										{description?.length || 0}/150
									</div>
								</div>
							</div>
						</IonSlide>
						<IonSlide>
							<div className="fc-center text-left w-2/3">
								<IonItem lines="none" color="primary">
									<input
										type="datetime-local"
										id="start-time"
										className="w-full border-b border-otto-tertiary dark:border-otto-tertiary-dark focus:outline-none rounded-none bg-transparent pl-1 placeholder-otto-placeholder focus:border-otto-secondary dark:focus:border-otto-secondary-dark"
										onChange={(e) => {
											setDate(e.target.value);
										}}
									/>
								</IonItem>
								<div className="w-full text-left subtext mt-2">
									Duration:
								</div>
								<IonRange
									color="secondary"
									className="w-full"
									max={360}
									snaps
									ticks={false}
									step={15}
									pin
									onIonChange={(e) =>
										setDuration(e.detail.value)
									}
								/>
								<div className="w-full">
									{document.getElementById('start-time')
										? HandleTime(
												document.getElementById(
													'start-time'
												).value
										  )
										: null}
								</div>
							</div>
						</IonSlide>
						<IonSlide>
							<AddPartyPhoto SetState={setPartyPhoto} />
						</IonSlide>
					</IonSlides>
					{slideIndex === 2 ? (
						!isSignUpLoading ? (
							<div className="fr-center w-full justify-evenly">
								<div
									className="sm-button p-2 whitespace-nowrap"
									onClick={PrevSlide}>
									Back
								</div>
								<div
									className="sm-button p-2 whitespace-nowrap"
									onClick={() => {
										VerifyStageThree();
									}}>
									Create
								</div>
							</div>
						) : (
							<div className="fr-center-center-full">
								<ImSpinner3 className="animate-spin text-4xl my-0.5 mt-1 text-otto-secondary dark:text-otto-secondary-dark" />
							</div>
						)
					) : (
						<div
							className={`fr-center w-full ${
								slideIndex === 0
									? 'justify-center'
									: 'justify-evenly'
							}`}>
							{slideIndex > 0 ? (
								<div
									onClick={PrevSlide}
									className="sm-button p-2 whitespace-nowrap">
									Back
								</div>
							) : null}
							<div
								onClick={() => {
									switch (slideIndex) {
										case 0:
											VerifyStageOne();
											break;

										case 1:
											VerifyStageTwo();
											break;

										case 2:
											VerifyStageThree();
											break;

										default:
											console.log('ERROR WITH INDEX');
											break;
									}
								}}
								className={`${
									slideIndex === 0 ? 'md-button' : 'sm-button'
								} p-2`}>
								Next
							</div>
						</div>
					)}
				</div>
			</IonContent>
			<IonToast
				position="top"
				isOpen={errorMessage !== null}
				message={`ERROR MESSAGE: ${errorMessage}`}
				color="secondary"
				duration={2000}
				onDidDismiss={() => {
					setErrorMessage(null);
				}}></IonToast>
		</IonPage>
	);
};

export default CreatePartyPage;
