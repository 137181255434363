import { useEffect, useState } from 'react';

import { AiOutlineClose } from 'react-icons/ai';
import { BiArrowBack } from 'react-icons/bi';
import OutsideClickHandler from 'react-outside-click-handler';
import { useHistory } from 'react-router';

const ModalOutline = ({
	SetState,
	ModalData,
	ModalType,
	SetSecondState,
	SetThirdState,
	BackButtonStatus,
	BackButtonAction,
	DynamicMessage,
	DynamicFunction1,
	DynamicFunction2,
}) => {
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);
	const router = useHistory();

	return (
		<div className="modal-backdrop">
			<OutsideClickHandler
				onOutsideClick={() => {
					SetState(false);
				}}>
				<div className="modal">
					<div className="relative fc-center-full-full">
						<AiOutlineClose
							onClick={() => {
								SetState(false);
							}}
							className="absolute top-2 right-2 text-2xl z-30 mix-blend-difference text-white"
						/>

						{BackButtonStatus ? (
							<BiArrowBack
								className="absolute top-2 left-2 text-2xl z-30 mix-blend-difference text-white"
								onClick={() => {
									BackButtonAction();
								}}
							/>
						) : null}

						<ModalType
							data={ModalData}
							SetSecondState={SetSecondState}
							DynamicMessage={DynamicMessage}
							SetState={SetState}
							SetThirdState={SetThirdState}
							DynamicFunction1={DynamicFunction1}
							DynamicFunction2={DynamicFunction2}
						/>
						{/* <Modal /> */}
					</div>
				</div>
			</OutsideClickHandler>
		</div>
	);
};

export default ModalOutline;
