import fetch from 'unfetch';
/**
 *
 * @param {*} toCheck The item to check. Can be a username or phone number
 * @param {*} typeOfData The type of item being checked. Can be 'username' or 'phoneNumber'
 * @returns
 */
const verifyInfo = async (toCheck, typeOfData) => {
	const url = `/api/signup/verifyInput`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
		},
		body: JSON.stringify({ toCheck: toCheck, typeOfData: typeOfData }),
	});

	if (res.ok) {
		return { status: 'succ' };
	} else {
		let message = await res.json();
		return { status: 'error', message: message.message };
	}
};
export default verifyInfo;
