import { useState, useEffect } from 'react';
import fetch from 'unfetch';

const spotify = async (code, redirect_url) => {
	const url = `/api/spotify/login`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({ code: code, redirect_url: redirect_url }),
	});
	let h = await res.json();
	return h;
};

export default spotify;
