import {
	IonContent,
	IonPage,
	IonSlide,
	IonSlides,
	IonToast,
} from '@ionic/react';
import {
	VerifyPasswords,
	VerifyPhoneNumber,
	VerifySMSPin,
} from '../../components/utils/FormInputVerification';
import { useEffect, useRef, useState } from 'react';

import BackArrow from '../../components/General/Navigation/BackArrow';
import BackgroundHeader from '../../components/General/UI/BackgroundHeader';
import MdLoadingButton from '../../components/General/UI/MdLoadingButton';
import MdPasswordInput from '../../components/General/UI/Forms/Md/MdPasswordInput';
import MdTelInput from '../../components/General/UI/Forms/Md/MdTelInput';
import MdTextInput from '../../components/General/UI/Forms/Md/MdTextInput';
import PhonePinInput from '../../components/General/UI/Phone/PhonePinInput';
import signupSMS from '../../data/signup/signupsms';
import verifyInfo from '../../data/signup/verifyInfo';
import verifyph from '../../data/signup/verifyPh';
import SubmitSignup from '../../data/signup/submitSignup';
import login from '../../data/UserAuth/Login';
import { useHistory } from 'react-router';

const SignUpPage = () => {
	const [isSignUpLoading, setSignUpLoadingStatus] = useState(null);
	const [slideIndex, setSlideIndex] = useState(0);
	const [phoneNumber, setPhoneNumber] = useState(null);
	const [password, setPassword] = useState(null);
	const [verifyPassword, setVerifyPassword] = useState(null);
	const [smsPin, setSMSPin] = useState(null);
	const [userName, setUserName] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const HandleSignUpSubmit = () => {
		setSignUpLoadingStatus(true);
	};

	const slideOptions = {
		initialSlide: 0,
		speed: 400,
	};
	const history = useHistory();
	const slides = useRef(null);

	useEffect(() => {
		slides.current.lockSwipes(true);
	}, []);

	const VerifyStageOne = async () => {
		let phoneRes = VerifyPhoneNumber(phoneNumber);
		if (phoneRes !== true) {
			setErrorMessage(phoneRes);
			return false;
		}
		let verifyPh = await verifyInfo(phoneNumber, 'phoneNumber');
		if (verifyPh.status !== 'error') {
			let passwordRes = true;
			if (passwordRes !== true) {
				setErrorMessage(passwordRes);
				return false;
			}
			await signupSMS(phoneNumber);

			NextSlide();
		} else {
			console.log(verifyPh);
			//please insert error message
		}
	};

	const VerifyStageTwo = async () => {
		let smsPinRes = VerifySMSPin(smsPin);
		if (smsPinRes !== true) {
			setErrorMessage(smsPinRes);
			return false;
		}

		// TODO:Isaiah NEEDS TO ACTUALLY VALIDATE
		let codeInput = await verifyph(smsPin, phoneNumber, password);
		if (codeInput.status !== 'error') {
			console.log('correct');
			NextSlide();
		} else {
			console.log('inc');
		}
	};
	const VerifyStageThree = async () => {
		let verify_username = await verifyInfo(userName, 'username');
		if (verify_username.status !== 'error') {
			let user = {
				password: password,
				firstName: firstName,
				lastName: lastName,
				username: userName,
				gender: 1,
			};
			let didSignUp = await SubmitSignup(user);
			if (didSignUp === 0) {
				login(phoneNumber, password).then((data) => {
					if (data.status !== 'error') {
						history.push('/home');
					}
				});
			}
		} else {
			console.log('Heyooo nope.');
		}
	};

	const NextSlide = async () => {
		await slides.current.lockSwipes(false);
		await slides.current.slideNext();
		await slides.current.lockSwipes(true);
		setSlideIndex(slideIndex + 1);
	};

	const PrevSlide = async () => {
		await slides.current.lockSwipes(false);
		await slides.current.slidePrev();
		await slides.current.lockSwipes(true);
		setSlideIndex(slideIndex - 1);
	};

	return (
		<IonPage>
			{/* <BackgroundHeader /> */}
			<div className="flex mt-2 z-50">
				{slideIndex === 0 ? (
					<span className="w-1/3 mx-2 border border-2 border-blue-400 bg-blue-500"></span>
				) : (
					<span className="w-1/3 mx-2 border border-2 border-gray-400 bg-gray-500"></span>
				)}
				{slideIndex === 1 ? (
					<span className="w-1/3 mx-2 border border-2 border-blue-400 bg-blue-500"></span>
				) : (
					<span className="w-1/3 mx-2 border border-2 border-gray-400 bg-gray-500"></span>
				)}
				{slideIndex === 2 ? (
					<span className="w-1/3 mx-2 border border-2 border-blue-400 bg-blue-500"></span>
				) : (
					<span className="w-1/3 mx-2 border border-2 border-gray-400 bg-gray-500"></span>
				)}
			</div>
			<IonContent scrollY={false} color="primary" fullscreen>
				<div className="fc-center-full-full justify-evenly relative">
					<BackArrow />

					<div className="text-7xl line-through -mb-24">OTTO</div>

					<form onSubmit={HandleSignUpSubmit} className="w-full">
						<IonSlides
							className="overflow-x-hidden"
							ref={slides}
							className="w-full"
							options={slideOptions}>
							<IonSlide className="flex flex-col">
								<div className=" text-xs">
									Let's get started with your phone number.
								</div>
								<div className="fc-center text-left w-3/4 h-full mt-12">
									<MdTelInput
										SetState={setPhoneNumber}
										AdditionalTW={'h-12 py-12 w-full px-12'}
									/>
								</div>
							</IonSlide>
							<IonSlide>
								<div className="fc-center w-2/3 text-center mt-24">
									<div className="text-xs">
										Please enter verification code that was
										sent to you
									</div>
									<PhonePinInput SetState={setSMSPin} />
									<div className="text-xs mt-4 hover:underline">
										Didn't receive text? Click here to send
										again
									</div>
								</div>
							</IonSlide>
							<IonSlide>
								<div className="fc-center text-left w-2/3">
									<div className="text-xs text-center">
										Lets get some more personal information
										about you.
									</div>
									<MdTextInput
										SetState={setUserName}
										Placeholder={'Username'}
									/>
									<MdTextInput
										SetState={setFirstName}
										Placeholder={'First Name'}
									/>
									<MdTextInput
										SetState={setLastName}
										Placeholder={'Last Name'}
									/>
									<MdPasswordInput
										SetState={setPassword}
										AdditionalTW="mt-2"
									/>
								</div>
							</IonSlide>
						</IonSlides>
					</form>
					{slideIndex === 3 ? (
						<MdLoadingButton
							ClickFunction={HandleSignUpSubmit}
							isLoading={isSignUpLoading}
							Message="Sign Up"
						/>
					) : (
						<div
							className={`fr-center w-1/2 ${
								slideIndex === 0
									? 'justify-center'
									: 'justify-between'
							}`}>
							{slideIndex > 0 ? (
								<div
									onClick={PrevSlide}
									className="sm-button p-2 whitespace-nowrap">
									Back
								</div>
							) : null}
							<div
								onClick={() => {
									switch (slideIndex) {
										case 0:
											VerifyStageOne();
											break;

										case 1:
											VerifyStageTwo();
											break;

										case 2:
											VerifyStageThree();
											break;

										default:
											console.log('ERROR WITH INDEX');
											break;
									}
								}}
								className={`${
									slideIndex === 0 ? 'md-button' : 'sm-button'
								} p-2`}>
								Next
							</div>
						</div>
					)}
				</div>
			</IonContent>
			<IonToast
				position="top"
				isOpen={errorMessage !== null}
				message={`ERROR MESSAGE: ${errorMessage}`}
				color="secondary"
				duration={2000}
				onDidDismiss={() => {
					setErrorMessage(null);
				}}></IonToast>
		</IonPage>
	);
};

export default SignUpPage;
