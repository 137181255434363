import fetch from 'unfetch';
import { resolve } from 'dns';

const autofillLocation = async (value) => {
	var apiKey = '29b60884911b4a30bde2ed2e996be435';
	console.log('VAL', value);
	console.log('ENCODED VAL', encodeURIComponent(value));
	var url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(
		value
	)}&limit=5&type=amenity&filter=countrycode:us&apiKey=${apiKey}`;
	let res;
	await fetch(url).then((resp) => {
		if (resp.ok) {
			console.log(resp);
			let toReturn = resp.json().then((data) => {
				console.log('DATA IS RESOLVED', data);
				res = data;
			});
			return toReturn;
			// resp.json().then((resp) => {
			// 	console.log('RESP', resp);
			// 	return resp
			// });
		} else {
			return false;
		}
	});
	console.log('res resolved is', res);
	return res;
};

export default autofillLocation;
