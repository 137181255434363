import fetch from 'unfetch';
import generateAuthToken from './generateAuthToken';
const searchSong = async (searchText, partyId) => {
	const url = `/api/spotify/search`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			search: searchText,
			access_token: JSON.parse(
				JSON.stringify(localStorage.getItem('spotify_access_token'))
			),
			partyId: partyId,
		}),
	});

	let h = await res.json();
	if (res.ok) {
		return h;
	} else {
		if (h.code === 3) {
			let b = await generateAuthToken();
			if (b) {
				console.log('here');
				searchSong(searchText);
			} else {
				return false;
			}
		}
		return false;
	}
};
export default searchSong;
