import login from "./Login";
const reAuthorize = async () => {
	if (localStorage.getItem("userData")) {
		let login_data = JSON.parse(localStorage.getItem("userData"));
		if (login_data?.data?.phoneNumber && login_data?.data?.password) {
			let resStatus = await login(
				login_data?.data?.phoneNumber,
				login_data?.data?.password
			);
			if (resStatus.status === "error") {
				return { status: "error" };
			} else {
				return { status: "success" };
			}
		}
	} else {
		return { status: "error" };
	}
};
export default reAuthorize;
