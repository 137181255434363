import { useState, useEffect } from 'react';
import fetch from 'unfetch';
/**
 *
 * @param {*} phoneNumber The phone number to verify
 * @param {*} password The password of the user
 * @returns Sends an sms to the user. Returns either status:success or status:error.
 */
const signupSMS = async (phoneNumber, password) => {
	const url = `/api/signup/signupsms`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
		},
		body: JSON.stringify({ phoneNumber: phoneNumber, password: password }),
	});

	if (res.ok) {
		return { status: 'success' };
	} else {
		localStorage.clear();
		return { status: 'error' };
	}
};
export default signupSMS;
