import { useEffect, useState } from 'react';

import { AiOutlineClockCircle } from 'react-icons/ai';
import { GetTimeStringFromTimeStamp } from '../../utils/FormatData';
import MdLoadingButton from '../UI/MdLoadingButton';
import { MdPinDrop } from 'react-icons/md';
import { useHistory } from 'react-router';

const PartyModal = ({ data }) => {
	const [isLoading, setLoadingStatus] = useState(false);

	useEffect(() => {
		console.log('partyData', data?.location?.address?.split(',')[0]);
	}, []);

	const router = useHistory();
	console.log("HEYOOOO")
	return (
		<div className="relative fc-center-full-full">
			{data?.party_image ? (
				<div className="w-full h-1/3 rounded-t-lg relative">
					<div className="modal-gradient-secondary"></div>
					<img
						src={data?.party_image}
						alt="party_image"
						className="w-full h-full object-cover rounded-t-lg"
					/>
				</div>
			) : (
				<div className="w-full h-1/3 bg-gradient-to-b rounded-t-lg from-otto-primary dark:from-otto-primary-dark" />
			)}
			<div className="text-2xl -mt-4 z-20 font-semibold">
				{data?.party_name || 'No Party Name'}
			</div>
			<div className="fr-center-evenly-full w-3/4">
				<div className="fr-center-center text-sm w-1/2">
					<AiOutlineClockCircle className="mr-1 flex-shrink-0" />
					{GetTimeStringFromTimeStamp(data?.start_time) ||
						'No Time Found'}
				</div>
				<div className="fr-center-center text-sm w-1/2">
					<MdPinDrop className="mr-1 flex-shrink-0" />
					<div className="w-full whitespace-nowrap truncate">
						{data?.location?.address?.split(',')[0] || 'Not Found'}
					</div>
				</div>
			</div>
			<div className="text-xs w-2/3 mt-4 h-34 overflow-y-scroll text-center">
				{data?.description}
			</div>
			<div className="fc-center-full mt-auto">
				<div className="mb-4">FRIENDS ATTENDING</div>
				<MdLoadingButton
					Message="View More Details"
					isLoading={isLoading}
					AdditionalTW="border-otto-primary dark:border-otto-primary-dark mb-4 py-2 hover:bg-otto-primary dark:hover:bg-otto-primary-dark px-1"
					ClickFunction={() => {
						setLoadingStatus(true);
						if (
							data?.owner ===
								JSON.parse(localStorage.getItem('userData'))
									.data.UUID ||
							data?.hosts.includes(
								JSON.parse(localStorage.getItem('userData'))
									.data.phoneNumber
							)
						) {
							router.push('/party/' + data?.partyId + '/host');
						} else {
							router.push('/party/' + data?.partyId);
						}
						setLoadingStatus(false);
					}}
				/>
			</div>
		</div>
	);
};

export default PartyModal;
